// Copyright 2024, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './assure.module.less';
import { Authenticator } from '../../models/models';
import { Row, Col } from 'antd';
import i18n from '../../i18n';
import AuthDetails from './AuthDetails';
import {
  getFactorTypeLabel,
  isSingleInstanceAuth,
} from '../../containers/authenticators/AuthenticatorList';
import { getFactorTypeIcon } from '../../utils/IconUtil';

interface Props {
  auth: Authenticator;
}

const AuthenticatorListItem: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const { auth } = props;
  let deviceModel = auth.deviceModel || '';
  deviceModel = deviceModel === 'unspecified' ? '' : deviceModel;

  const translatedDeviceModel =
    deviceModel &&
    i18n.t(`data.${deviceModel}`, {
      ns: 'deviceModel',
      defaultValue: deviceModel,
    });

  const removeModelFromAndroidRegex =
    /^([a-zA-Z0-9_\-() ]+)\([a-zA-Z0-9\-_ ]+\)$/gm;
  deviceModel = deviceModel.replace(removeModelFromAndroidRegex, '$1');

  let deviceModelToDisplay = translatedDeviceModel || deviceModel;
  deviceModelToDisplay = deviceModelToDisplay
    ? `(${deviceModelToDisplay})`
    : '';

  const content = isSingleInstanceAuth(auth.factorType) ? (
    <Row justify="space-between">
      <Col>
        {getFactorTypeIcon(auth.factorType, '#333333', {
          height: '24px',
          width: '24px',
        })}
      </Col>
      <Col style={{ width: '23%' }}>
        <span className={classes.authenticatorTypeLabel}>
          {getFactorTypeLabel(auth.factorType, t)}
        </span>
      </Col>
      <AuthDetails key={auth.authenticatorId} auth={auth} />
    </Row>
  ) : (
    <Row justify="space-between" align="middle">
      <Col span={7}>
        <span className={classes.authenticatorLabel}>
          {auth.shortName && auth.shortName === 'unknown'
            ? ' '
            : `${auth.shortName} `}
          {deviceModelToDisplay}
        </span>
      </Col>
      <AuthDetails
        key={auth.authenticatorId}
        auth={auth}
        deviceModelToDisplay={deviceModelToDisplay}
      />
    </Row>
  );

  return (
    <div
      className={classes.authenticator}
      key={auth.authenticatorId}
      data-testid="authenticator-content"
    >
      {content}
    </div>
  );
};

export default AuthenticatorListItem;
