import React, { useState } from 'react';
import { Modal } from 'antd';
import classes from './ConfirmationModal.module.less';
import { Button, ButtonVariant } from '@imprivata-cloud/components';

interface Props {
  visible: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ChooseAssuranceProcessModal: React.FC<Props> = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(props.visible);

  const closeModal = () => {
    setIsModalOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    props.onConfirm();
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          className={classes.modal}
          cancelText={null}
          okText={null}
          open={props.visible}
          data-testid="choose-assurance-type-modal"
          onCancel={closeModal}
          onOk={handleConfirmNavigationClick}
          footer={null}
          maskClosable={false}
        >
          <div
            // Temporary. Update when UX spec is finalized
            style={{
              margin: '20px',
              height: '250px',
              display: 'flex',
              justifyContent: 'space-evenly',
              flexDirection: 'column',
            }}
          >
            <div className={classes.modalContent}>
              {'Choose assurance type'}
            </div>
            <Button
              variant={ButtonVariant.PRIMARY}
              onClick={handleConfirmNavigationClick}
              label={'Supervised Assurance'}
            />
            <Button
              variant={ButtonVariant.PRIMARY}
              disabled={true}
              onClick={handleConfirmNavigationClick}
              label={'Individual Assurance'}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ChooseAssuranceProcessModal;
