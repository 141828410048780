// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './assure.module.less';
import {
  AssuranceType,
  Authenticator,
  FactorTypeEnum,
} from '../../models/models';
import { Col } from 'antd';
import { useDispatch } from 'react-redux';
import { unenrollAuthenticatorActions } from '../../containers/authenticators/store/actions';
import { computeDateDiffInDays } from '../../utils/misc';
import { AuthenticatorUnenrollRequest } from '../../api/types';
import ConfirmationModal from '../modals/ConfirmationModal';
import Chip from '../common/Chip';
import {
  setAuthenticatorIdAction,
  startAssureAuthenticatorAction,
  supervisedAssuranceStartAction,
} from '../../containers/assurance/store/actions';
import getConfig from '../../appConfigUtils';
import { startWorkflowAction } from '../../containers/workflow/store/actions';
import { v4 as uuidv4 } from 'uuid';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import ChooseAssuranceProcessModal from '../modals/ChooseAssuranceProcessModal';

const { FF_05_30_2023_ASSURANCE_ENABLED, CHOOSE_ASSURANCE_TYPE_DISABLED } =
  getConfig();

interface Props {
  auth: Authenticator;
  deviceModelToDisplay?: string;
}

const AuthDetails: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [
    chooseAssuranceProcessModalVisibility,
    setChooseAssuranceProcessModalVisibility,
  ] = useState(false);
  const [authenticatorToUnenroll, setAuthenticatorToUnenroll] =
    useState<Authenticator>();

  const computeDateDiff = (dateStr: string) => {
    const days = computeDateDiffInDays(dateStr);
    if (days === 0) {
      return t('authenticators.today');
    } else if (days === 1) {
      return t('authenticators.yesterday');
    } else if (days > 365) {
      return t('authenticators.lastYear');
    } else {
      return t('authenticators.last-used', {
        lastUsed: days,
      });
    }
  };
  const { auth } = props;

  const unenroll = () => {
    if (authenticatorToUnenroll) {
      const req: AuthenticatorUnenrollRequest = {
        authenticatorId: authenticatorToUnenroll.authenticatorId,
      };
      dispatch(startWorkflowAction(uuidv4()));
      dispatch(unenrollAuthenticatorActions.request(req));
    }
  };

  const confirm = (e: FormEvent) => {
    e.preventDefault();
    setConfirmationVisible(true);
    setAuthenticatorToUnenroll(auth);
  };

  const cancel = () => {
    setConfirmationVisible(false);
    setAuthenticatorToUnenroll(undefined);
  };

  const onAssureAuthenticatorClick = () => {
    dispatch(setAuthenticatorIdAction(auth.authenticatorId));
    dispatch(startWorkflowAction(uuidv4()));
    dispatch(supervisedAssuranceStartAction.request(auth.authenticatorId));
    dispatch(startAssureAuthenticatorAction());
  };

  let contentText = '';
  let method = '';
  let titleText = '';

  switch (auth.factorType) {
    case FactorTypeEnum.IMPRIVATA_ID:
      method = t('factors.IMPRIVATA_ID');
      titleText = t('authenticators.confirm-unenroll-title', { method });
      contentText = t('authenticators.confirm-unenroll-iid', {
        phone: props.deviceModelToDisplay,
      });
      break;
    case FactorTypeEnum.PROX_CARD:
      method = t('factors.PROXIMITY_CARD');
      titleText = t('authenticators.confirm-unenroll-title', { method });
      contentText = t('authenticators.confirm-unenroll-other', {
        method,
      });
      break;
    case FactorTypeEnum.IMPRIVATA_PIN:
      method = t('factors.IMPRIVATA_PIN');
      titleText = t('authenticators.confirm-unenroll-title', { method });
      contentText = t('authenticators.confirm-unenroll-other', {
        method,
      });
      break;
  }

  return (
    <>
      <Col span={6}>
        <span className={classes.authUsedLabel}>
          {computeDateDiff(auth.lastUsedAt)}
        </span>
      </Col>
      <Col span={2} className={classes.assuranceLabelContainer}>
        {auth.assuranceType === AssuranceType.IAL2_ASSURED && (
          <Chip label="EPCS" />
        )}
      </Col>
      <Col span={6}>
        <>
          {!CHOOSE_ASSURANCE_TYPE_DISABLED &&
            chooseAssuranceProcessModalVisibility && (
              <ChooseAssuranceProcessModal
                visible={true}
                onConfirm={() => onAssureAuthenticatorClick()}
                onClose={() => setChooseAssuranceProcessModalVisibility(false)}
              />
            )}
          {confirmationVisible && (
            <ConfirmationModal
              title={titleText}
              cancelText={t('authenticators.dont-unenroll')}
              okText={t('authenticators.unenroll')}
              content={contentText}
              visible={true}
              onConfirm={() => unenroll()}
              onDiscard={cancel}
              onClose={cancel}
            ></ConfirmationModal>
          )}
          <div className={classes.authenticatorOperationContainer}>
            {FF_05_30_2023_ASSURANCE_ENABLED &&
              auth.assuranceType === AssuranceType.UNSPECIFIED &&
              auth.factorType === FactorTypeEnum.IMPRIVATA_ID && (
                <div className={classes.assuranceLinkHorizontalDivider}>
                  <Button
                    className={classes.enrollButton}
                    data-testid="assure-authenticator-button"
                    type="text"
                    variant={ButtonVariant.TEXT}
                    label={t('authenticators.assure')}
                    onClick={() =>
                      CHOOSE_ASSURANCE_TYPE_DISABLED
                        ? onAssureAuthenticatorClick()
                        : setChooseAssuranceProcessModalVisibility(v => !v)
                    }
                  />
                </div>
              )}
            <Button
              className={classes.unEnrollButton}
              data-testid="unenroll-authenticator-button"
              type="text"
              variant={ButtonVariant.TEXT}
              label={t('authenticators.unenroll')}
              onClick={confirm}
            />
          </div>
        </>
      </Col>
    </>
  );
};

export default AuthDetails;
