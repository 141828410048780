// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import classes from './ChooseAuth.module.less';
import { FactorTypeEnum } from '../../models/models';
import ChooseAuth from './ChooseAuth';

export interface FactorDetails {
  factor: FactorTypeEnum;
  enabled: boolean;
}

interface Props {
  factorDetails: FactorDetails[];
}

const ChooseAuths: React.FC<Props> = ({ factorDetails }) => {
  const content = factorDetails.map((details: FactorDetails) => (
    <ChooseAuth details={details} key={details.factor} />
  ));

  return <ul className={classes.flexContainer}>{content}</ul>;
};

export default ChooseAuths;
