import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { comparePropsEqual } from '@imprivata-cloud/common';
import {
  AuthnModule as _Authn,
  EventFunction,
  EventType,
} from '@imprivata-cloud/authn';

import { HOME_ROUTE } from '../../routers/route-names';
import history from '../../routers/history';
import styles from './LoginContainer.module.less';
import {
  clearErrorsAction,
  startClientSessionAction,
  stateSaveUsernameAction,
} from './store/actions';
import { StorageKeys } from './store/constants';
import {
  CLIENT_NAME,
  contextType,
  Headers,
  resourceType,
} from '../../api/constants';
import { UNEXPECTED_ERROR_MESSAGE } from '../../error-handler/constants';
import { v4 as uuidv4 } from 'uuid';
import { setHeader } from '../../api/api';
import { loginSelector2 } from './store/selectors';
import { Banner } from '@imprivata-cloud/components';

const updateSearchParams = (workflowId: string) => {
  const paramsBefore = new URLSearchParams(window.location.search);
  setHeader(Headers.ImprWorkflowId, workflowId);
  paramsBefore.set('contextType', contextType);
  paramsBefore.set('resourceType', resourceType);
  paramsBefore.set('workflowId', workflowId);
  history.replace({ search: paramsBefore.toString() });
};

const AuthnModule = React.memo(_Authn, (prev, next) => {
  return comparePropsEqual(prev, next);
});

const LoginContainer = ({ location }: RouteComponentProps) => {
  const { t } = useTranslation();

  const [paramsAdded, setParamsAdded] = useState(true);
  const dispatch = useDispatch();

  const workflowId = useMemo(() => uuidv4(), []);

  useEffect(() => {
    if (workflowId) {
      updateSearchParams(workflowId);
      setParamsAdded(true);
    }

    return () => {
      const paramsAfter = new URLSearchParams(window.location.search);
      paramsAfter.delete('contextType');
      paramsAfter.delete('resourceType');
      paramsAfter.delete('workflowId');
      history.push({ search: paramsAfter.toString() });
    };
  }, [workflowId]);

  const { sessionErrorCode, userId } = useSelector(loginSelector2);

  useEffect(() => {
    if (sessionErrorCode) {
      const message = t(`${sessionErrorCode}`, {
        defaultValue: UNEXPECTED_ERROR_MESSAGE,
      });

      const clearError = () => dispatch(clearErrorsAction);

      Banner({
        id: sessionErrorCode,
        datatestid: sessionErrorCode,
        type: 'warning',
        message,
        onClick: clearError,
        onClose: clearError,
        duration: 10,
      });
    }
  }, [sessionErrorCode, dispatch, t]);

  const loggedInLandingRoute = HOME_ROUTE + location.search;
  const tenantId = new URLSearchParams(location.search).get('tenantId');

  const onEvent: EventFunction = useCallback(
    ({ event, data }) => {
      switch (event) {
        case EventType.AUTHENTICATED:
          if (data?.username) {
            dispatch(stateSaveUsernameAction(data.username));
          }
          if (data?.codingContext) {
            localStorage.setItem(
              StorageKeys.CODING_CTX_ID,
              data.codingContext.id.toString(),
            );
            localStorage.setItem(
              StorageKeys.CODING_CTX_DATAKEY,
              JSON.stringify(Array.from(data.codingContext.dataKeyBuf32)),
            );
            localStorage.setItem(
              StorageKeys.CODING_CTX_ENCRYPTED_DATAKEY,
              JSON.stringify(
                Array.from(data.codingContext.encryptedDataKeyBuf),
              ),
            );
          }
          dispatch(startClientSessionAction.request({}));
          break;
      }
    },
    [dispatch],
  );

  const title = useMemo(() => t('login.astra-title'), [t]);

  return (
    <>
      <div className={styles.pageTitle} data-testid="page-title">
        {title}
      </div>
      <div style={{ backgroundColor: 'white' }}>
        {userId && <Redirect to={`${loggedInLandingRoute}`} />}
        {paramsAdded && (
          <AuthnModule
            tenantId={tenantId || ''}
            tracing={{
              workflowId,
            }}
            hideAddAnotherMethod
            requestConfig={{
              clientName: CLIENT_NAME,
            }}
            onEvent={onEvent}
          />
        )}
      </div>
    </>
  );
};

export default LoginContainer;
