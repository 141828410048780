export const getVersionInfo = () => {
  return {
    BUILD_VERSION: IMPR_BUILD_VERSION || '',
    APP_VERSION: IMPR_APP_VERSION || '0.0.0',
  };
};
export const getBaseUrl = () => import.meta.env.BASE_URL;
export const getCurrentEnv = () => {
  const url = window.location.host;
  const urlRegex = /\w+\.\w+\.(\w+)\.\w+\.(\w+)/gim;
  const matches = urlRegex.exec(url);
  let currentEnv = 'prod';

  if (url?.includes('localhost')) {
    currentEnv = 'dev';
  } else if (Array.isArray(matches) && matches.length > 0) {
    matches.shift();
    const [appEnv, tld] = matches;
    console.debug(appEnv, tld);
    currentEnv = appEnv;
  }
  return currentEnv;
};
