// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Authenticator } from '../../../models/models';
import { RootState } from '../../../store/rootReducer';
import { EnrollAuthenticatorState, ListAuthenticatorsState } from './reducer';

export const getSelectedAuthenticatorState = (
  state: RootState,
): Authenticator | undefined => {
  return state.authenticatorManagement.listAuthenticators.selectedAuthenticator;
};

export const getAuthenticatorsState = (
  state: RootState,
): ListAuthenticatorsState => {
  return state.authenticatorManagement.listAuthenticators;
};

export const getEnrollmentState = (
  state: RootState,
): EnrollAuthenticatorState => {
  return state.authenticatorManagement.enrollAuthenticator;
};
