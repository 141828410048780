import { EventsType } from 'react-idle-timer';

export enum ErrorCodes {
  'USER_CANNOT_BE_SUPERVISOR' = 'user-cannot-be-supervisor',
}

export enum AuthenticationCancelReasonCode {
  'USER_CANCEL' = 'user-cancel',
}

export const USER_ACTIVITIES_EVENTS: EventsType[] = [
  'mousemove',
  'keydown',
  'wheel',
  'mousewheel',
  'mousedown',
];

export const ASSURANCE_IDLE_TIMEOUT_MS = 30 * 1000;
