import { client } from './client';
import { authz } from './endpoint-names';
import { CreateClientSessionPayload } from './types';
import { deleteHeader, setHeader } from './api';
import { Headers } from './constants';

/**
 * TenantId is required because we validate the session on page refresh
 * Without TenantId we show an error message and no validation is done
 */
export function startClientSession({
  tenantId,
}: {
  tenantId: string;
}): Promise<CreateClientSessionPayload> {
  setHeader(Headers.ImprTenantId, tenantId);

  return client.post(authz.START_CLIENT_SESSION).then(res => res.data);
}

export function logout(): Promise<Record<string, never>> {
  deleteHeader(Headers.ImprInAppSession);
  return client.post(authz.LOGOUT).then(res => res.data);
}
