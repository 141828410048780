import React from 'react';
import { Row } from 'antd';
import SetTitle from '../../utils/DynamicTitleHelper';
import classes from './AssuranceWizardLayout.module.less';
import Footer from '../common/Footer';
import Header from '../common/Header';

type Props = {
  children: JSX.Element;
  header?: string;
  title?: string /** @description set title of the browser window */;
};

const SupervisorLoginLayout: React.FC<Props> = ({
  children,
  header,
  title,
}) => {
  SetTitle(title || 'Assurance Wizard');
  return (
    <div className={classes.setupArea}>
      <Header data-testid="page-header" title="" showUserInfoDropdown={false} />
      <div className={classes.gridContainer}>
        <div className={classes.gridItem}>
          {header && (
            <Row>
              <div className={classes.header}>{header}</div>
            </Row>
          )}
          {children}
        </div>
      </div>
      <Footer data-testid="page-footer" />
    </div>
  );
};

export default SupervisorLoginLayout;
