// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Layout, Spin } from 'antd';
import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import {
  AUTHENTICATORS_ROUTE,
  HOME_ROUTE,
  ASSURE_SUPERVISOR,
  IID_ENROLL_ROUTE,
  ENROLL_AUTHENTICATOR_ROUTE,
  PIN_ENROLL_ROUTE,
} from '../../routers/route-names';
import AuthenticatorManagement from './AuthenticatorManagement';
import IIDEnrollment from './IIDEnrollment';
import { RedirectWithQuery, RouteWithQuery } from '../../utils/routingHelpers';
import PageLayout from '../../components/layout/PageLayout';

import classes from '../../styles/layout.module.less';
import { useSelector } from 'react-redux';
import { userDisplayNameSelector } from '../login/store/selectors';
import AssuranceRouter from '../assurance/AssuranceRouter';
import EnrollAuthenticator from './EnrollAuthenticator';
import PINEnrollment from './PINEnrollment';
import { StorageKeys } from '../login/store/constants';

const AuthenticatorLayout: React.FC = () => {
  const userDisplayName = useSelector(userDisplayNameSelector);

  useEffect(() => {
    const beforeUnloadCallback = () => {
      localStorage.setItem(StorageKeys.LOGOUT_EVENT_TRIGGERED, 'true');
      sessionStorage.setItem(StorageKeys.KEEP_USER_SESSION_ACTIVE, 'true');
    };
    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    };
  }, []);

  return (
    <Spin spinning={false}>
      <Layout
        className={classes.mainContainer}
        data-testid="authenticator-layout"
      >
        <PageLayout title={userDisplayName || 'DisplayName not set'}>
          <div
            id="layout-container"
            data-testid="layout-container"
            className={classes.layoutContainer}
          >
            <Switch>
              <RouteWithQuery
                exact
                path={AUTHENTICATORS_ROUTE}
                component={AuthenticatorManagement}
              />
              <RedirectWithQuery
                exact
                path={HOME_ROUTE}
                to={AUTHENTICATORS_ROUTE}
              />

              <RouteWithQuery
                exact
                path={ENROLL_AUTHENTICATOR_ROUTE}
                component={EnrollAuthenticator}
              />

              <RouteWithQuery
                exact
                path={IID_ENROLL_ROUTE}
                component={IIDEnrollment}
              />

              <RouteWithQuery
                exact
                path={PIN_ENROLL_ROUTE}
                component={PINEnrollment}
              />

              <RouteWithQuery
                exact
                path={ASSURE_SUPERVISOR}
                component={AssuranceRouter}
              />

              <RouteWithQuery
                exact
                path="*"
                component={AuthenticatorManagement}
              />
            </Switch>
          </div>
        </PageLayout>
      </Layout>
    </Spin>
  );
};

export default AuthenticatorLayout;
