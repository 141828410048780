import { FactorTypeEnum } from '../models/models';
import React from 'react';
import ImprId from '../assets/astra_impr_id_24x24.svg?react';
import ProxCard from '../assets/astra_prox_badge_24x24.svg?react';
import Pin from '../assets/pin.svg?react';

export const getFactorTypeIcon = (
  factorType: FactorTypeEnum,
  fill: string,
  style: Record<string, string>,
) => {
  switch (factorType) {
    case FactorTypeEnum.PROX_CARD:
      return <ProxCard fill={fill} style={style} data-testid="prox-icon" />;
    case FactorTypeEnum.IMPRIVATA_ID:
      return <ImprId fill={fill} style={style} data-testid="iid-icon" />;
    case FactorTypeEnum.IMPRIVATA_PIN:
      return <Pin fill={fill} style={style} data-testid="pin-icon" />;
    default:
      console.error('Unsupported factor type ' + factorType);
      return <></>;
  }
};
