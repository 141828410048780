import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SetTitle from '../../utils/DynamicTitleHelper';
import { Form, Row } from 'antd';
import { Button, ButtonVariant, InputBox } from '@imprivata-cloud/components';
import { useHistory, useLocation } from 'react-router-dom';
import { AUTHENTICATORS_ROUTE } from '../../routers/route-names';
import {
  AuthenticatorEnrollRequest,
  PinSettingsResponse,
} from '../../api/types';
import { FactorTypeEnum } from '../../models/models';
import { enrollAuthenticatorActions } from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedAuthenticatorState } from './store/selectors';
import AssuranceWizardLayout from '../../components/layout/AssuranceWizardLayout';
import classes from './styles.module.less';
import { startWorkflowAction } from '../workflow/store/actions';
import { v4 as uuidv4 } from 'uuid';
import { createNewCodingContext, getAuthnCodingContext } from '../login/util';
import { getPinSettings } from '../../api/authenticatorsService';
import { redirectWithQuery } from '../../utils/routingHelpers';
import { addErrorHandlerAction } from '../../error-handler/store/action';
import { ERROR_CODES } from '../../error-handler/constants';
import { useStartGlobalUiIdleTimeout } from '../../utils/hooks';
import pinSrc from '../../assets/pin-167x228.png';
import { PIN_VALUES_NOT_MATCHING } from './store/constants';

const PINEnrollment: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const [pinSettings, setPinSettings] = useState<PinSettingsResponse>();

  const { search } = useLocation();
  const selectedAuthenticator = useSelector(getSelectedAuthenticatorState);

  const { startTimer } = useStartGlobalUiIdleTimeout();
  startTimer();

  useEffect(() => {
    getPinSettings()
      .then(res => {
        setPinSettings(res);
      })
      .catch(err => {
        dispatch(addErrorHandlerAction({ errorCode: ERROR_CODES.FATAL }));
        redirectWithQuery(AUTHENTICATORS_ROUTE);
      });
  }, [dispatch]);

  const onKeyPress = (e: React.KeyboardEvent) => {
    const specialCharRegex = pinSettings?.allowNonNumeric
      ? new RegExp(/^[a-zA-Z0-9]*$/)
      : new RegExp(/^[0-9]*$/);
    const ctrlKeyCode = [
      'ArrowLeft',
      'ArrowRight',
      'Escape',
      'Enter',
      'Ctrl',
      'Backspace',
      'Delete',
      'Tab',
    ];
    if (ctrlKeyCode.includes(e.key) || e.ctrlKey) {
      // Allow keyboard shortcuts including ctrl+A for selecting all text inside input
      return true;
    }
    if (!specialCharRegex.test(e.key)) {
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  };

  const handleChange = () => {
    setSaveDisabled(false);
    form
      .validateFields()
      .then(values => {
        if (
          !values.pinValue ||
          values.pinValue.length < (pinSettings?.minLength ?? 4) ||
          !values.pinValueConfirm ||
          values.pinValueConfirm.length < (pinSettings?.minLength ?? 4)
        ) {
          setSaveDisabled(true);
        }
      })
      .catch(() => {
        setSaveDisabled(true);
      });
  };

  const enroll = async () => {
    const authCodingContext = getAuthnCodingContext();
    const codingContext = authCodingContext
      ? authCodingContext
      : await createNewCodingContext();
    const factorType = selectedAuthenticator
      ? selectedAuthenticator.factorType
      : FactorTypeEnum.IMPRIVATA_PIN;
    form.validateFields().then(values => {
      //check pin values
      if (values?.pinValue !== values?.pinValueConfirm) {
        dispatch(addErrorHandlerAction({ errorCode: PIN_VALUES_NOT_MATCHING }));
      } else {
        // TEMP: Placeholder
        const systemDataJson = {
          version: '1.0',
          osName: 'ToBeImplemented',
          osVersion: 'ToBeImplemented',
          computerName: 'ToBeImplemented',
        };
        const enrollRequest: AuthenticatorEnrollRequest = {
          factorType,
          factorData: codingContext.encryptJson({
            pin: values.pinValue,
          }),
          systemData: codingContext.encryptJson(systemDataJson),
          deviceData: '', // placeholder
        };

        dispatch(startWorkflowAction(uuidv4()));
        dispatch(
          enrollAuthenticatorActions.request({
            authenticatorEnrollRequest: enrollRequest,
            codingContext: codingContext.buildImprCodingCtxHeader(),
          }),
        );
        form.resetFields();
        setSaveDisabled(true);
      }
    });
  };

  SetTitle(t('navigation.enroll'));
  return !pinSettings ? (
    <></>
  ) : (
    <AssuranceWizardLayout>
      <div className={classes.topSpacing}>
        <div className={classes.sidebarGrid}>
          <div>
            <img
              className={classes.phoneImage}
              alt="PIN"
              src={pinSrc}
              data-testid="pin-icon"
            />
          </div>
          <div className={classes.centeredArea}>
            <Row>
              <div className={classes.header}>
                {t('authenticators.enroll.pin.header')}
              </div>
            </Row>
            <Row>
              <div className={classes.pinText}>
                <p>{t('authenticators.enroll.pin.body-text1')}</p>
                <p>{t('authenticators.enroll.pin.body-text2')}</p>
              </div>
            </Row>
            <Form
              form={form}
              layout="vertical"
              name="register"
              onChange={handleChange}
              onFinish={enroll}
            >
              <Row>
                <Form.Item
                  data-testid="enroll-form-pin"
                  name="pinValue"
                  label={t('authenticators.enroll.pin.input-label')}
                >
                  <InputBox
                    autoFocus
                    data-testid="enroll-form-pin-value"
                    size="small"
                    type="password"
                    maxLength={pinSettings?.maxLength ?? 4}
                    className={classes.pinEnrollButton}
                    onKeyDown={onKeyPress}
                    onPaste={e => {
                      e.preventDefault();
                    }}
                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  data-testid="enroll-form-pin-confirm"
                  name="pinValueConfirm"
                  label={t('authenticators.enroll.pin.input-confirm-label')}
                >
                  <InputBox
                    data-testid="enroll-form-pin-value-confirm"
                    size="small"
                    type="password"
                    maxLength={pinSettings?.maxLength ?? 4}
                    className={classes.pinEnrollButton}
                    onKeyDown={onKeyPress}
                    onPaste={e => {
                      e.preventDefault();
                    }}
                  />
                </Form.Item>
              </Row>
              <Row className={classes.pinEnrollButton}>
                <Button
                  type="primary"
                  variant={ButtonVariant.PRIMARY}
                  label={t('actions.continue')}
                  data-testid="enroll-button"
                  disabled={isSaveDisabled}
                  htmlType="submit"
                />
              </Row>
              <Row className={classes.pinEnrollButton}>
                <Button
                  type="text"
                  variant={ButtonVariant.TEXT}
                  label={t('actions.later')}
                  data-testid="cancel-button"
                  onClick={() => {
                    history.push(AUTHENTICATORS_ROUTE + search);
                  }}
                  disabled={false}
                />
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </AssuranceWizardLayout>
  );
};

export default PINEnrollment;
