// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Store, Dispatch } from 'redux';
import { configureStore as configureReduxStore } from '@reduxjs/toolkit';
import { createEpicMiddleware, Epic } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { History } from 'history';
import { RootAction } from './rootAction';
import rootReducer, { RootState } from './rootReducer';
import rootEpic from './rootEpic';
import i18n from '../i18n';

export type RootStore = Store<ReturnType<typeof rootReducer>>;

type ConfigOptions = {
  initialState?: Partial<RootState>;
  isProduction?: boolean;
  history?: History;
};

export function configureStore(options?: ConfigOptions): RootStore {
  const { initialState, isProduction, history } = options ?? {};
  const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState
  >({ dependencies: { history, i18n } });

  const store = configureReduxStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(epicMiddleware),
    preloadedState: initialState,
    devTools: !isProduction ? { trace: true, traceLimit: 25 } : false,
  });

  if (!isProduction && import.meta.hot) {
    const epic$ = new BehaviorSubject<Epic<RootAction, RootAction, RootState>>(
      rootEpic,
    );
    // Every time a new epic is given to epic$ it
    // will unsubscribe from the previous one then
    // call and subscribe to the new one because of
    // how switchMap works
    const hotReloadingEpic: Epic<RootAction, RootAction, RootState> = (
      ...args
    ) => epic$.pipe(switchMap(epic => epic(...args)));

    epicMiddleware.run(hotReloadingEpic);

    import.meta.hot.accept('./rootReducer', () =>
      store.replaceReducer(rootReducer),
    );
    import.meta.hot.accept('./rootEpic', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextRootEpic = require('./rootEpic').default;
      epic$.next(nextRootEpic);
    });
  } else {
    epicMiddleware.run(rootEpic);
  }

  return store;
}

export type AppDispatch = Dispatch<RootAction>;
