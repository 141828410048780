import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AuthnModule, EventType } from '@imprivata-cloud/authn';
import { jsonToBase64 } from '@imprivata-cloud/data-privacy-js';
import { generatePKCECodes } from '../../utils';
import {
  setPkseCodesAction,
  supervisedAssuranceCompleteAction,
} from './store/actions';
import { PKCECodes } from '../../api/types';
import supervisorSrc from '../../assets/supervisor.png';
import { RootState } from '../../store/rootReducer';
import {
  CLIENT_NAME,
  Headers,
  resourceType,
  supervisedAssuranceContextType,
} from '../../api/constants';
import history from '../../routers/history';
import classes from './styles.module.less';
import { addErrorHandlerAction } from '../../error-handler/store/action';
import { UNEXPECTED_ERROR_CODE } from '../../error-handler/constants';
import {
  ErrorCodes,
  ErrorCodesForSupervisorLogin,
  StorageKeys,
} from '../login/store/constants';
import { redirectWithQuery } from '../../utils/routingHelpers';
import { AUTHENTICATORS_ROUTE } from '../../routers/route-names';
import { client } from '../../api/client';
import SupervisorLoginLayout from '../../components/layout/SupervisorLoginLayout';

const SupervisorAuthenticate: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Getting workflowId from the header which was set at the beginning of assurance flow
  const workflowId = client.defaults.headers.common[
    Headers.ImprWorkflowId
  ] as string;

  const tenantId = new URLSearchParams(window.location.search).get('tenantId');

  useEffect(() => {
    const paramsBefore = new URLSearchParams(window.location.search);
    paramsBefore.set('contextType', supervisedAssuranceContextType);
    paramsBefore.set('resourceType', resourceType);
    paramsBefore.set('workflowId', workflowId);
    history.replace({ search: paramsBefore.toString() });

    return () => {
      const paramsAfter = new URLSearchParams(window.location.search);
      paramsAfter.delete('contextType');
      paramsAfter.delete('resourceType');
      paramsAfter.delete('workflowId');
      history.replace({ search: paramsAfter.toString() });
    };
  }, [workflowId]);

  useEffect(() => {
    generatePKCECodes().then((codes: PKCECodes) => {
      dispatch(
        setPkseCodesAction({
          codeVerifier: codes.codeVerifier,
          codeChallenge: codes.codeChallenge,
        }),
      );
    });
    return () => {
      dispatch(setPkseCodesAction(null));
    };
  }, [dispatch]);

  const assuranceId = useSelector<RootState, string | null>(
    state => state.assuranceWorkflow.assuranceId,
  );
  const oidcCodeVerifier = useSelector<RootState, string | undefined>(
    state => state.assuranceWorkflow.pkceCodes?.codeVerifier,
  );

  const pkceCodeChallenge = useSelector<RootState, string | undefined>(
    state => state.assuranceWorkflow.pkceCodes?.codeChallenge,
  );

  return (
    <SupervisorLoginLayout>
      <div className={classes.supervisorAuthenticateContainer}>
        <div className={classes.sideBox}>
          <img
            className={classes.insetImageLeft}
            alt="Supervisor Authenticate!"
            src={supervisorSrc}
            data-testid="supervisor-icon"
          />
          <div className={classes.witness}>
            {t('assurance.acknowledge-header')}
          </div>
          <div data-testid="acknowledge-msg" className={classes.ackMsg}>
            {t('assurance.acknowledge')}
          </div>
        </div>
        {pkceCodeChallenge && (
          <AuthnModule
            tenantId={tenantId || ''}
            isTimerDisabled={true}
            hideFactorSwitch={true}
            requestConfig={{
              clientName: CLIENT_NAME,
              oidcRequestData: {
                codeChallenge: pkceCodeChallenge,
                codeChallengeMethod: 'S256',
              },
              inAppSessionHeader: jsonToBase64({
                contextType: supervisedAssuranceContextType,
              }),
            }}
            tracing={{ workflowId: workflowId || '' }}
            onEvent={({ event, data }) => {
              switch (event) {
                case EventType.AUTHENTICATED:
                  if (
                    (data as { oidcAuthorizationCode: string })
                      ?.oidcAuthorizationCode &&
                    assuranceId &&
                    oidcCodeVerifier
                  ) {
                    dispatch(
                      supervisedAssuranceCompleteAction.request({
                        oidcAuthorizationCode: (
                          data as { oidcAuthorizationCode: string }
                        ).oidcAuthorizationCode,
                        assuranceId,
                        oidcCodeVerifier,
                      }),
                    );
                  } else {
                    dispatch(
                      addErrorHandlerAction({
                        errorCode: UNEXPECTED_ERROR_CODE,
                      }),
                    );
                  }
                  break;
                case EventType.FAILURE:
                case EventType.ERROR:
                  if (!data?.code) break;

                  /**
                   * @NOTE
                   * - `ambiguous-username` and `invalid-credentials` doesn't reset the username as of now, and lets user type in password
                   * - `credential-expired` reset username and terminate the session and creates new session and doesn't redirect user to homepage
                   * - all other errors(e.g. `idp-disabled`) resets username, terminates session and redirects user to homepage
                   */
                  const shouldRedirect = (errorCode: string) =>
                    !(
                      errorCode === ErrorCodes.CREDENTIALS_EXPIRED ||
                      // Q?: shouldn't AMBIGUOUS_USERNAME also make the user enter username?
                      errorCode === ErrorCodes.AMBIGUOUS_USERNAME ||
                      errorCode ===
                        ErrorCodesForSupervisorLogin.INVALID_CREDENTIALS
                    );
                  if (data.code === ErrorCodes.CREDENTIALS_EXPIRED) {
                    localStorage.removeItem(
                      StorageKeys.IS_SUPERVISOR_AUTHENTICATING,
                    );
                  } else {
                    // Handle errors where session is created as we do not have a session created event.
                    localStorage.setItem(
                      StorageKeys.IS_SUPERVISOR_AUTHENTICATING,
                      'true',
                    );
                  }

                  if (shouldRedirect(data.code)) {
                    dispatch(addErrorHandlerAction({ errorCode: data.code }));
                    redirectWithQuery(AUTHENTICATORS_ROUTE);
                  }
                  break;
                case EventType.SUCCESS:
                  localStorage.setItem(
                    StorageKeys.IS_SUPERVISOR_AUTHENTICATING,
                    'true',
                  );
                  break;
                default:
                  break;
              }
            }}
          />
        )}
      </div>
    </SupervisorLoginLayout>
  );
};

export default SupervisorAuthenticate;
