// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { authenticatorsEpic } from '../containers/authenticators/store/epics';
import { assuranceEpics } from '../containers/assurance/store/epics';
import { loginEpic } from '../containers/login/store/epics';
import { workflowEpic } from '../containers/workflow/store/epics';

const rootEpic = combineEpics(
  authenticatorsEpic,
  loginEpic,
  assuranceEpics,
  workflowEpic,
);

export type RootEpic = ReturnType<typeof rootEpic>;

export default rootEpic;
