// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import clsx from 'clsx';
import { Layout } from 'antd';

import classes from './PageLayout.module.less';

interface PageLayoutProps {
  title: string;
  children?: React.ReactNode;
  className?: string;
}

const { Content } = Layout;

const PageLayout: React.FC<PageLayoutProps> = function PageLayout({
  title,
  children,
  className,
}) {
  return (
    <Layout
      data-testid="page-layout"
      className={clsx(classes.layout, className)}
    >
      <Content data-testid="page-content" className={classes.pageContent}>
        {children}
      </Content>
    </Layout>
  );
};

export default PageLayout;
