// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineReducers } from 'redux';
import { authenticatorsReducer as authenticatorManagement } from '../containers/authenticators/store/reducer';
import { loginReducer as login } from '../containers/login/store/reducers';
import { assuranceWorkflowReducer as assuranceWorkflow } from '../containers/assurance/store/reducer';
import { workflowReducer } from '../containers/workflow/store/reducer';
import { errorHandler } from '../error-handler/store/reducer';

const rootReducer = combineReducers({
  authenticatorManagement,
  login,
  assuranceWorkflow,
  workflowReducer,
  errorHandler,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
