import { combineEpics, Epic } from 'redux-observable';
import { map, tap, filter, switchMap, ignoreElements } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { from } from 'rxjs';
import { RootAction } from '../../../store/rootAction';
import {
  startAssureAuthenticatorAction,
  idProofingSessionActions,
  setCurrentWorkflow,
  goToNextStep,
  assuranceWorkflowSequenceError,
  verifyEmailAction,
  wrongOtpAction,
  assuranceIdErrorAction,
  verifyPhoneAction,
  assureSupervisorAction,
  startVerifyIdentityAction,
  individualAssuranceCompletedAction,
} from './actions';
import { History } from 'history';
import { ASSURE_SUPERVISOR } from '../../../routers/route-names';
import { RootState } from '../../../store/rootReducer';
import { AssuranceWorkflowState } from './reducer';
import { Banner } from '@imprivata-cloud/components';
import { individualAssuranceEpics } from './individualAssurance.epics';
import { supervisedAssuranceEpics } from './supervisedAssurance.epics';

export const assuranceWorkflowNextStep = (
  assuranceWorkflow: AssuranceWorkflowState,
) => {
  const currentWorkflow = assuranceWorkflow.currentWorkflow;
  const currentStep = assuranceWorkflow.currentStep;

  if (currentWorkflow === null || currentStep === null)
    return assuranceWorkflowSequenceError();

  switch (currentStep) {
    case 'verify-email':
      return verifyEmailAction();
    case 'verify-phone':
      return verifyPhoneAction();
    case 'id-proofing':
      return idProofingSessionActions.request();
    case 'verify-identity':
      return startVerifyIdentityAction();

    case 'verify-authenticator':
      return;
    case 'supervisor-authenticate':
      return assureSupervisorAction();
    case 'assurance-complete':
      if (currentWorkflow === 'individual')
        return individualAssuranceCompletedAction();
      else return assuranceWorkflowSequenceError(); // TODO add new error action
    default:
      return assuranceWorkflowSequenceError();
  }
};

const assuranceWorkflowControllerEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([goToNextStep])),
    switchMap(() => {
      const action = assuranceWorkflowNextStep(state$.value.assuranceWorkflow);
      return from(action ? [action] : []);
    }),
  );

/** @note WARNING: make sure this isn't registered twice, since both individual and supervisor uses this epic */
const wrongOtpEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(wrongOtpAction)),
    tap(() => {
      Banner({
        type: 'error',
        message: 'Wrong OTP',
        duration: 5,
        datatestid: 'otp-error',
      });
    }),
    ignoreElements(),
  );

const assuranceIdNotFoundEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(assuranceIdErrorAction)),
    tap(() => {
      Banner({
        type: 'error',
        message: 'AssuranceId not found',
        duration: 5,
        datatestid: 'assurance-id-error',
      });
    }),
    ignoreElements(),
  );

const assureAuthenticatorEpic: Epic<RootAction> = (
  action$,
  _,
  { history }: { history: History },
) =>
  action$.pipe(
    filter(isActionOf(startAssureAuthenticatorAction)),
    map(() => {
      history.push(ASSURE_SUPERVISOR + window.location.search);
      const basicAssuranceFeatureFlag = new URLSearchParams(
        window.location.search,
      ).get('assurance');
      return setCurrentWorkflow(
        basicAssuranceFeatureFlag ? 'workflow-2' : 'supervised',
      );
    }),
  );

export const assuranceEpics = combineEpics(
  assureAuthenticatorEpic,
  assuranceWorkflowControllerEpic,

  assuranceIdNotFoundEpic,
  wrongOtpEpic,

  individualAssuranceEpics,
  supervisedAssuranceEpics,
);
