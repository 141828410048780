import { useSelector } from 'react-redux';
import { LOGIN_ROUTE } from '../../routers/route-names';
import { loginSelector } from '../login/store/selectors';
import { RedirectWithQuery } from '../../utils/routingHelpers';

type Props = {
  children: JSX.Element;
};

// Keeping this here instead of hooks file since it might change in future
const useIsAuthenticated = (): boolean => {
  const { user } = useSelector(loginSelector);
  return user?.userId !== undefined;
};

export default function ProtectedRoute({ children }: Props): JSX.Element {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return <RedirectWithQuery to={`${LOGIN_ROUTE}`} />;
  }
  return <>{children}</>;
}
