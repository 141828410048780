// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { RawSpanAttributes } from '@imprivata-cloud/common';
import { tracer, SpanNames } from '../../tracing';

export function startCreateClientSessionTracingSpan(
  attributes?: RawSpanAttributes,
): void {
  tracer.startSubspanFromParent(
    SpanNames.CREATE_CLIENT_SESSION,
    SpanNames.AUTHENTICATION,
    attributes,
  );
}

export function endCreateClientSessionTracingSpan(
  attributes: RawSpanAttributes,
): void {
  tracer.endSpan(SpanNames.CREATE_CLIENT_SESSION, attributes);
}
