// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { authenticators } from './endpoint-names';
import {
  ApiResponse,
  AuthenticatorsPayload,
  AuthenticatorEnrollRequest,
  AuthenicatorEnrollResponse,
  AuthenticatorUnenrollRequest,
  AuthenicatorUnenrollResponse,
  AuthenticatorsRequest,
  AssuranceTypeUpdateRequest,
  ApiError,
  PinSettingsResponse,
} from './types';
import rxClient from './rxClient';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { deleteHeader, setHeader } from './api';
import { Headers } from './constants';
import { client } from './client';

export function listAuthenticators$(
  payload: AuthenticatorsRequest,
): Observable<ApiResponse<AuthenticatorsPayload>> {
  setHeader(Headers.ImprTenantId, payload.tenantId);

  /**
   * @note
   * Start assurance flow then go back!
   * At this point the InAppSession is still there and the request to listAuthenticator
   * will fail because the BFF isn't expecting this header.
   * The two observables doesn't depend on each other thus the fetching can sometimes
   * happen before the headers are deleted.
   */
  deleteHeader(Headers.ImprInAppSession);
  deleteHeader(Headers.ImprWorkflowId);

  return rxClient
    .post<ApiResponse<AuthenticatorsPayload>>(
      authenticators.LIST_AUTHENTICATORS,
    )
    .pipe(map(res => res.data));
}

export function enrollAuthenticator$(
  payload: AuthenticatorEnrollRequest,
  codingContextHeader?: string,
): Observable<ApiResponse<AuthenicatorEnrollResponse>> {
  let axiosConfig = {};
  axiosConfig = {
    headers: {
      [Headers.ImprCodingCtx]: codingContextHeader,
    },
  };
  return rxClient
    .post<ApiResponse<AuthenicatorEnrollResponse>>(
      authenticators.ENROLL_AUTHENTICATOR,
      payload,
      axiosConfig,
    )
    .pipe(map(res => res.data));
}

export function unenrollAuthenticator$(
  payload: AuthenticatorUnenrollRequest,
): Observable<ApiResponse<AuthenicatorUnenrollResponse>> {
  return rxClient
    .post<ApiResponse<AuthenicatorUnenrollResponse>>(
      authenticators.UNENROLL_AUTHENTICATOR,
      payload,
    )
    .pipe(map(res => res.data));
}

export function updateAssuranceType$(
  payload: AssuranceTypeUpdateRequest,
): Observable<ApiResponse<{ error: ApiError }>> {
  return rxClient
    .post<ApiResponse<{ error: ApiError }>>(
      authenticators.UPDATE_ASSURANCE_TYPE,
      payload,
    )
    .pipe(map(res => res.data));
}

export async function getPinSettings(): Promise<PinSettingsResponse> {
  const response = await client.post<PinSettingsResponse>(
    authenticators.GET_PIN_SETTINGS,
  );

  return response.data;
}
