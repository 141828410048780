// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const contextType = 'generic-app-login';
export const supervisedAssuranceContextType = 'supervised-assurance';

export const resourceType = 'user-web-ui';

export const CLIENT_NAME = 'user-web';

export enum Headers {
  ImprAuthnResource = 'Impr-Authn-Resource',
  ImprClientName = 'Impr-Client-Name',
  ImprTenantId = 'Impr-Tenant-Id',
  ImprInAppSession = 'Impr-In-App-Session',
  ImprCodingCtx = 'Impr-Coding-Ctx',
  ImprWorkflowId = 'Impr-Workflow-Id',
  XContentTypeOptions = 'X-Content-Type-Options',
  XSSProtection = 'X-XSS-Protection',
  XFrameOptions = 'X-Frame-Options',
  StrictTransportSecurity = 'Strict-Transport-Security',
}

// API version for authn and enroll
export enum ApiVersion {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}
