// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Col, Dropdown, Layout, MenuProps, Row, Space, Typography } from 'antd';
import { Button, ButtonSize } from '@imprivata-cloud/components';

import classes from './Header.module.less';
import { useLogout } from '../../containers/logout/hooks';
import Icon from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { userEmailSelector } from '../../containers/login/store/selectors';
import getIcon from '../../assets/svg/logout.svg?react';
import DownArrowIcon from '../../assets/svg/down_arrow.svg?react';

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  showUserInfoDropdown?: boolean;
}

const { Title } = Typography;

const Header: React.FC<HeaderProps> = ({
  title,
  className,
  showUserInfoDropdown = true,
  ...rest
}) => {
  const [logout] = useLogout();
  const { t } = useTranslation();
  const userEmail = useSelector(userEmailSelector) || '';

  const firstLetter = title ? title.substring(0, 1).toUpperCase() : '';
  const items: MenuProps['items'] = [
    {
      key: '1',
      disabled: true,
      style: {
        pointerEvents: 'none',
      },
      label: (
        <div
          className={classes.usernameItem}
          data-testid="username-item"
          onClick={e => {
            e.preventDefault();
          }}
        >
          <div className={classes.centeredCircle}>{firstLetter}</div>
          {userEmail}
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className={classes.centered} data-testid="logout-item">
          <Button
            type="text"
            block={true}
            size={ButtonSize.MAJOR}
            label={t('navigation.logout') as string}
            data-testid="logout-btn"
            icon={<Icon component={getIcon} />}
          />
        </div>
      ),
      style: {
        backgroundColor: 'white',
      },
      onClick: e => {
        e.domEvent.preventDefault();
        logout();
      },
    },
  ];

  return (
    <Layout.Header className={clsx(classes.header, className)} {...rest}>
      <Layout>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginTop: '5px' }}
        >
          {showUserInfoDropdown && (
            <>
              <Col>
                <div className={classes.circle}>
                  <span>{firstLetter}</span>
                </div>
              </Col>
              <Col>
                <Dropdown
                  overlayStyle={{ width: '292px', height: '228px' }}
                  menu={{ items }}
                  trigger={['click', 'hover']}
                >
                  {/* eslint-disable-next-line */}
                  <a
                    href="#"
                    data-testid="user-menu"
                    onClick={e => e.preventDefault()}
                  >
                    <Space>
                      <div>{title}</div>
                      <Icon
                        component={DownArrowIcon}
                        style={{ color: 'white', fontSize: '18px' }}
                      />
                    </Space>
                  </a>
                </Dropdown>
              </Col>
            </>
          )}
          <Col flex="auto"></Col>
          <Col className={classes.logoutContainer} />
          <Col>
            <Title className={classes.title} level={1}>
              {t(`common.header-title`)}
            </Title>
          </Col>
        </Row>
      </Layout>
    </Layout.Header>
  );
};

export default Header;
