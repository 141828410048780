import { CreateClientSessionPayload } from '../../../api/types';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { AuthenticationCancelReasonCode } from '../../authenticators/assurance/store/constants';

export const stateSaveUsernameAction = createAction(
  'state-save-username',
)<string>();

export const setSessionErrorCodeAction = createAction(
  'set-session-error-code',
)<string>();

export const startClientSessionAction = createAsyncAction(
  'start-client-session/REQUEST',
  'start-client-session/SUCCESS',
  'start-client-session/FAILURE',
)<{ tenantId?: string }, CreateClientSessionPayload, string>();

export const logoutAction = createAsyncAction(
  'logout/REQUEST',
  'logout/SUCCESS',
  'logout/FAILURE',
)<void, void, void>();

export const clearErrorsAction = createAction('clear-errors')<void>();

export const cancelSupervisorAuthenticationAction = createAsyncAction(
  'supervisor/CANCEL_AUTHENTICATION_REQUEST',
  'supervisor/CANCEL_AUTHENTICATION_SUCCESS',
  'supervisor/CANCEL_AUTHENTICATION_FAILURE',
)<{ reasonCode: AuthenticationCancelReasonCode }, void, void>();
