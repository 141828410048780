import { SyntheticEvent, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { LOGOUT_ROUTE } from '../../routers/route-names';

export function useLogout(): [(_: void | SyntheticEvent) => void] {
  const location = useLocation();
  const history = useHistory();

  const logout = useCallback(
    _ => history.push(LOGOUT_ROUTE + location.search),
    [location, history],
  );

  return [logout];
}
