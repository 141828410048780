// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { client, fetchWithKeepAlive } from './client';
import { Headers } from './constants';

export function setHeader(header: Headers, value: string): void {
  client.defaults.headers.common[header] = value;
  fetchWithKeepAlive.setHeader(header, value);
}

export function deleteHeader(header: Headers): void {
  delete client.defaults.headers.common[header];
  fetchWithKeepAlive.removeHeader(header);
}
