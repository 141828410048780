import { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import AuthenticatorLayout from './containers/authenticators/AuthenticatorLayout';
import { HOME_ROUTE, LOGIN_ROUTE, LOGOUT_ROUTE } from './routers/route-names';
import { ejectInterceptors } from './api/interceptors';
import { RouteWithQuery } from './utils/routingHelpers';
import LoginRoutes from './containers/login/LoginRoutes';
import ProtectedRoute from './containers/protected-route/ProtectedRoute';
import Logout from './containers/logout/Logout';
import { useStartWindowCloseIdleTimeout } from './utils/hooks';
import { useDispatch } from 'react-redux';
import { logoutAction } from './containers/login/store/actions';
import { StorageKeys } from './containers/login/store/constants';

function App(): JSX.Element {
  const { startTimer } = useStartWindowCloseIdleTimeout();
  const dispatch = useDispatch();
  startTimer();

  useEffect(() => {
    // If session storage exists in case of logoutEventTriggered then the event is page refresh.
    // Else if session storage is cleared then it is browser close.
    if (
      localStorage.getItem(StorageKeys.LOGOUT_EVENT_TRIGGERED) &&
      !sessionStorage.getItem(StorageKeys.KEEP_USER_SESSION_ACTIVE)
    ) {
      dispatch(logoutAction.request());
      localStorage.removeItem(StorageKeys.LOGOUT_EVENT_TRIGGERED);
    } else {
      localStorage.removeItem(StorageKeys.LOGOUT_EVENT_TRIGGERED);
      sessionStorage.removeItem(StorageKeys.KEEP_USER_SESSION_ACTIVE);
    }
    return () => {
      ejectInterceptors();
    };
  }, [dispatch]);

  return (
    <Switch>
      <RouteWithQuery
        path={LOGIN_ROUTE}
        component={LoginRoutes}
      ></RouteWithQuery>
      <RouteWithQuery exact path={LOGOUT_ROUTE} component={Logout} />

      <RouteWithQuery
        path={HOME_ROUTE}
        render={() => (
          <ProtectedRoute>
            <AuthenticatorLayout />
          </ProtectedRoute>
        )}
      />
    </Switch>
  );
}

export default App;
