import { _getUrls, _getEnvVariable } from '@imprivata-cloud/common';
import { ApiVersion } from './api/constants';
import { QueryParamKey } from './containers/login/store/constants';
import featureFlags from './featureFlags.json';
import { getCurrentEnv } from './utils/build';

export type FeatureFlags = typeof featureFlags;

declare global {
  interface Window {
    _appConfig: Record<string, string>;
  }
}

export type AppEnvConfig = {
  API_URL: string;
  AUTHN_API_VERSION: ApiVersion;
  METADATA_URL: string;
  ZIPKIN_TRACING_URL?: string;
  OTLP_TRACING_URL?: string;
  COLLECT_TRACING?: string;

  FF_05_30_2023_ASSURANCE_ENABLED?: boolean;
  CHOOSE_ASSURANCE_TYPE_DISABLED?: boolean;
  PIN_DISABLED?: boolean;
};

let envConfig: AppEnvConfig;

export function _initAppConfig(
  window: Window,
  env: ImportMetaEnv,
): AppEnvConfig {
  let authnApiVersion: ApiVersion;
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(QueryParamKey.AUTHN_API_VERSION)) {
    authnApiVersion = urlParams.get(
      QueryParamKey.AUTHN_API_VERSION,
    ) as ApiVersion;
  } else {
    authnApiVersion = _getEnvVariable(
      window,
      env,
      'API_VERSION',
      ApiVersion.V2,
    ) as ApiVersion;
  }

  const { metadataUrl, apiUrl, tracingUrl } = _getUrls();

  const currentEnv = getCurrentEnv();
  let features: Partial<AppEnvConfig> = featureFlags['prod'];

  if (Object.keys(featureFlags).includes(currentEnv)) {
    features = featureFlags[currentEnv as keyof FeatureFlags];
  }

  return {
    API_URL:
      apiUrl || (_getEnvVariable(window, env, 'API_URL', apiUrl) as string),
    AUTHN_API_VERSION: authnApiVersion,
    METADATA_URL:
      metadataUrl ||
      (_getEnvVariable(window, env, 'METADATA_URL', metadataUrl) as string),
    OTLP_TRACING_URL: tracingUrl,
    ZIPKIN_TRACING_URL: _getEnvVariable(window, env, 'ZIPKIN_TRACING_URL') as
      | string
      | undefined,
    COLLECT_TRACING: _getEnvVariable(window, env, 'COLLECT_TRACING') as
      | string
      | undefined,
    ...features,
  };
}

export default function getConfig(): AppEnvConfig {
  if (!envConfig) {
    envConfig = _initAppConfig(window, import.meta.env);
  }
  return envConfig;
}
