import { ContextNames } from './config';

function buildCodeForAlreadyExists(context: ContextNames) {
  if (context === ContextNames.IID_ENROLLMENT) {
    return 'enroll-already-exists';
  } else if (context === ContextNames.PIN_ENROLLMENT) {
    return 'enroll-pin-already-exists';
  } else {
    return 'already-exists';
  }
}

function buildCodeForInvalidCredentials(context: ContextNames) {
  // `user-invalid-credentials` is scoped to this user-web-ui repo only. it does not come from the BFF or other downstream service
  if (context === ContextNames.LOGIN) {
    return 'user-invalid-credentials';
  } else if (context === ContextNames.ASSURANCE) {
    return 'assurance.error.verify-invalid-credentials';
  } else if (context === ContextNames.IID_ENROLLMENT) {
    return 'enroll-invalid-credentials';
  } else {
    return 'invalid-credentials';
  }
}

function getRepeatedDigitsCode(context: ContextNames, errorCode: string) {
  if (context === ContextNames.PIN_ENROLLMENT) {
    return 'enroll-repeated-digits';
  } else {
    return errorCode;
  }
}

function getConsecutiveDigitsCode(context: ContextNames, errorCode: string) {
  if (context === ContextNames.PIN_ENROLLMENT) {
    return 'enroll-consecutive-digits';
  } else {
    return errorCode;
  }
}

export function getErrorMessageCode(
  context: ContextNames,
  errorCode: string,
): string {
  switch (errorCode) {
    case 'invalid-credentials':
      return buildCodeForInvalidCredentials(context);
    case 'session-expired':
      if (context === ContextNames.LOGIN) {
        return 'session-expired';
      } else {
        return errorCode;
      }
    case 'not-found':
      if (context === ContextNames.LOGIN) {
        return 'user-not-found';
      } else {
        return errorCode;
      }
    case 'fatal':
      if (context === ContextNames.LOGIN) {
        return 'user-login-fatal';
      } else {
        return errorCode;
      }
    case 'already-exists':
      return buildCodeForAlreadyExists(context);
    case 'user-cannot-be-supervisor':
      if (context === ContextNames.SUPERVISOR) {
        return 'assurance.error.user-cannot-be-supervisor';
      } else {
        return errorCode;
      }
    case 'invalid-grant':
      if (context === ContextNames.SUPERVISOR) {
        return 'assurance.error.invalid-grant';
      } else {
        return errorCode;
      }
    case 'assurance-not-started':
      if (context === ContextNames.ASSURANCE) {
        return 'assurance.error.assurance-not-started';
      } else {
        return errorCode;
      }
    case 'assurance-in-progress':
      if (context === ContextNames.ASSURANCE) {
        return 'assurance.error.assurance-in-progress';
      } else {
        return errorCode;
      }
    case 'assurance-id-not-found':
      if (context === ContextNames.ASSURANCE) {
        return 'assurance.error.assurance-id-not-found';
      } else {
        return errorCode;
      }
    case 'consecutive-digits':
      return getConsecutiveDigitsCode(context, errorCode);
    case 'repeated-digits':
      return getRepeatedDigitsCode(context, errorCode);
    default:
      return errorCode;
  }
}
