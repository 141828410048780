import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorConfiguration } from './ErrorConfig';
import { ErrorType } from './types';
import { clearErrorHandlerAction } from './store/action';
import { useTranslation } from 'react-i18next';
import { UNEXPECTED_ERROR_MESSAGE } from './constants';
import { Banner } from '@imprivata-cloud/components';
import { BannerProps } from '@imprivata-cloud/components/lib/components/Banner/Banner';
import i18n from '../i18n';
import { clearErrorsAction } from '../containers/login/store/actions';
import { getErrorHandlerState } from './store/selectors';
import { compareObjects } from '@imprivata-cloud/common';

const defaultBannerProps: Partial<BannerProps> = {
  duration: 10,
};

export const ErrorHandler = ({ children }: { children: React.ReactNode }) => {
  const errorHandler = useSelector(getErrorHandlerState, (prev, next) => {
    return compareObjects(prev, next);
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (errorHandler?.errorCode) {
      // get error configuration
      const errorConfig = errorConfiguration[errorHandler.errorCode];
      const clearError = () => {
        dispatch(clearErrorHandlerAction());
        dispatch(clearErrorsAction());
      };
      if (errorConfig) {
        switch (errorConfig.errorType) {
          case ErrorType.FULL_PAGE:
            // redirect for full page errors
            break;
          case ErrorType.GLOBAL:
          default:
            const primaryMsg = t(`${errorConfig.errorCode}-primary` as never, {
              ...errorHandler.errorMessageParams,
              defaultValue: t(`${errorConfig.errorCode}` as never, {
                defaultValue: UNEXPECTED_ERROR_MESSAGE,
              }),
            });
            const secondaryMsg = i18n.exists(
              `${errorConfig.errorCode}-secondary`,
            )
              ? t(`${errorConfig.errorCode}-secondary` as never)
              : undefined;
            Banner({
              ...errorConfig,
              id: errorConfig.errorCode,
              datatestid: errorConfig.errorCode,
              message: primaryMsg,
              description: secondaryMsg,
              onClick: clearError,
              onClose: clearError,
              ...defaultBannerProps,
            });
        }
      } else {
        // If error code is not there in the config, ignore the error - no user message
        dispatch(clearErrorHandlerAction());
      }
    }
  }, [dispatch, errorHandler, t]);

  return <> {children} </>;
};
