import {
  switchMap,
  map,
  catchError,
  tap,
  filter,
  ignoreElements,
} from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import { Epic, combineEpics } from 'redux-observable';
import { RootAction } from '../../../store/rootAction';
import { setHeader } from '../../../api/api';
import { Headers } from '../../../api/constants';
import { ErrorCodes, StorageKeys } from './constants';
import { saveToStorage, removeFromStorage } from '../../../utils';

import {
  startClientSessionAction,
  logoutAction,
  setSessionErrorCodeAction,
} from './actions';
import { logout, startClientSession } from '../../../api/sessionService';
import { isActionOf } from 'typesafe-actions';

import { endWorkflowAction } from '../../workflow/store/actions';
import { cancelSupervisorAuthenticationEpic } from './cancelAuthentication.epic';
import { RootState } from '../../../store/rootReducer';
import {
  endCreateClientSessionTracingSpan,
  startCreateClientSessionTracingSpan,
} from '../tracing';
import { addErrorHandlerAction } from '../../../error-handler/store/action';
import { ERROR_CODES } from '../../../error-handler/constants';
import { User } from './reducers';

const saveTenantId = (tenantId: string) => {
  setHeader(Headers.ImprTenantId, tenantId);
};

const clearSessionData = () => {
  removeFromStorage(StorageKeys.USER);
};

const saveSessionData = ({ user }: { user: User }) => {
  saveToStorage(StorageKeys.USER, JSON.stringify(user));
};

const userLoginEndWorkflowEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(
      isActionOf([
        startClientSessionAction.failure,
        startClientSessionAction.success,
      ]),
    ),
    switchMap(() => {
      // clear workflowId ONLY for non-supervisor login. we need the workflow id for supervisor workflow
      if (state$.value.assuranceWorkflow.currentWorkflow) return from(EMPTY);
      return of(endWorkflowAction());
    }),
  );

export const startClientSessionEpic: Epic<RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(startClientSessionAction.request)),
    tap(() => {
      startCreateClientSessionTracingSpan();
    }),
    tap(({ payload }) => {
      const { tenantId } = payload.tenantId ? payload : state$.value.login;
      if (tenantId) {
        saveTenantId(tenantId);
      }
    }),
    switchMap(({ payload }) => {
      const { tenantId } = payload.tenantId ? payload : state$.value.login;
      if (!tenantId) {
        return of(
          addErrorHandlerAction({ errorCode: ERROR_CODES.MISSING_TENANT_ID }),
        );
      }

      return from(startClientSession({ tenantId })).pipe(
        tap(response => {
          endCreateClientSessionTracingSpan({
            response,
          });
        }),
        map(response => {
          saveSessionData({
            user: response.user,
          });
          return startClientSessionAction.success(response);
        }),
        catchError(error => {
          endCreateClientSessionTracingSpan({ error });
          return of(
            addErrorHandlerAction({ errorCode: error.code }),
            startClientSessionAction.failure(ErrorCodes.CLIENT_SESSION_ERROR),
          );
        }),
      );
    }),
  );

export const logoutEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(logoutAction.request)),
    tap(() => {
      clearSessionData();
      localStorage.removeItem(StorageKeys.CODING_CTX_ID);
      localStorage.removeItem(StorageKeys.CODING_CTX_DATAKEY);
      localStorage.removeItem(StorageKeys.CODING_CTX_ENCRYPTED_DATAKEY);
      localStorage.removeItem(StorageKeys.LOGOUT_EVENT_TRIGGERED);
      sessionStorage.removeItem(StorageKeys.KEEP_USER_SESSION_ACTIVE);
    }),
    switchMap(() => {
      return from(logout()).pipe(
        map(() => {
          return logoutAction.success();
        }),
        catchError(() => {
          clearSessionData();
          return of(logoutAction.success());
        }),
      );
    }),
  );

export const sessionErrorEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(setSessionErrorCodeAction)),
    tap(() => {
      clearSessionData();
    }),
    ignoreElements(),
  );

export const loginEpic = combineEpics(
  logoutEpic,
  sessionErrorEpic,
  userLoginEndWorkflowEpic,
  cancelSupervisorAuthenticationEpic,
  startClientSessionEpic,
);
