// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import classes from './ChooseAuth.module.less';
import { FactorDetails } from './ChooseAuths';
import { FactorTypeEnum } from '../../models/models';
import { useTranslation } from 'react-i18next';
import AuthBox from './AuthBox';
import { IID_ENROLL_ROUTE, PIN_ENROLL_ROUTE } from '../../routers/route-names';

interface Props {
  details: FactorDetails;
}

const ChooseAuth: React.FC<Props> = ({ details }) => {
  const { t } = useTranslation();
  let content;

  switch (details.factor) {
    case FactorTypeEnum.IMPRIVATA_ID:
      content = (
        <AuthBox
          label={t('factors.IMPRIVATA_ID')}
          enabled={details.enabled}
          factor={details.factor}
          imgWidth="32px"
          enrollRoute={IID_ENROLL_ROUTE}
        />
      );
      break;

    case FactorTypeEnum.PROX_CARD:
      content = (
        <AuthBox
          label={t('factors.PROX_CARD')}
          enabled={details.enabled}
          factor={details.factor}
          imgWidth="32px"
        />
      );
      break;

    case FactorTypeEnum.IMPRIVATA_PIN:
      content = (
        <AuthBox
          label={t('factors.IMPRIVATA_PIN')}
          enabled={details.enabled}
          factor={details.factor}
          imgWidth="32px"
          enrollRoute={PIN_ENROLL_ROUTE}
        />
      );
      break;

    default:
      content = <>??</>;
  }

  const classesToUse = details.enabled
    ? `${classes.flexItemBase} ${classes.flexItem}`
    : `${classes.flexItemBase} ${classes.flexItemDisabled}`;
  return <li className={classesToUse}>{content}</li>;
};

export default ChooseAuth;
