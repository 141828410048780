// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { tracer, SpanNames } from '../tracing/index';

export function startInitAppTracingSpan(): void {
  tracer.startSpan(SpanNames.INIT_APP);
}

export function endInitAppTracingSpan(): void {
  tracer.endSpan(SpanNames.INIT_APP);
}
