import { ErrorConfigMap, ErrorType } from './types';
import {
  ENROLL_ACTIVATE_FAILED,
  ENROLL_ALREADY_EXISTS,
  ENROLL_INVALID_CREDENTIALS,
  ENROLLED_BY_ANOTHER_USER,
  IDP_DISABLED,
  IDP_LOCKED,
  NO_TENANT_ID,
  PIN_ALREADY_EXISTS,
  PIN_CONSECUTIVE_DIGITS,
  PIN_REPEATED_DIGITS,
  PIN_VALUES_NOT_MATCHING,
  SESSION_EXPIRED,
  UNEXPECTED_ERROR_CODE,
} from './constants';

export const enum GLOBAL_ERRORS {
  ASSURANCE_IN_PROGRESS = 'assurance.error.assurance-in-progress',
  ASSURANCE_ID_NOT_FOUND = 'assurance.error.assurance-id-not-found',
  ASSURANCE_NOT_STARTED_ERROR = 'assurance.error.assurance-not-started',
  ASSURANCE_NOT_STARTED = 'assurance-not-started',
  ASSURANCE_VERIFY_INVALID_CREDENTIALS = 'assurance.error.verify-invalid-credentials',
  AUTHENTICATE_IDP_DISABLED = 'idp-disabled',
  AUTHENTICATE_IDP_LOCKED = 'idp-locked',
  FATAL = 'fatal',
  ASSURANCE_FATAL = 'assurance.error.fatal',
  ASSURANCE_USER_CANNOT_BE_SUPERVISOR = 'assurance.error.user-cannot-be-supervisor',
  ASSURANCE_INVALID_GRANT = 'assurance.error.invalid-grant',
  ENROLL_ALREADY_EXISTS = 'enroll-already-exists',
  ENROLL_INVALID_CREDENTIALS = 'enroll-invalid-credentials',
  ENROLL_ACTIVATE_FAILED = 'enroll-activate-failed',
  NOT_FOUND = 'not-found',
  PERMISSION_DENIED = 'forbidden',
  IMPLEMENTATION_DEFECT = 'implementation-defect',
  INTERNAL = 'internal',
  INTERNAL_1 = 'INTERNAL',
  INVALID_TENANT_ID = 'invalid-tenant-id',
  INVALID_TENANT_STATE = 'invalid-tenant-state',
  INCOMPLETE_OPERATION = 'incomplete-operation',
  MISSING_TENANT_ID = 'missing-tenant-id',
  ENROLL_PIN_ALREADY_EXISTS = 'enroll-pin-already-exists',
  PIN_CONSECUTIVE_DIGITS = 'enroll-consecutive-digits',
  PIN_REPEATED_DIGITS = 'enroll-repeated-digits',
  ENROLLED_BY_ANOTHER_USER = 'enrolled-by-another',
  PIN_VALUES_NOT_MATCHING = 'pin-values-not-matching',
}

export const errorConfiguration: ErrorConfigMap = {
  [GLOBAL_ERRORS.ASSURANCE_IN_PROGRESS]: {
    errorCode: GLOBAL_ERRORS.ASSURANCE_IN_PROGRESS,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ASSURANCE_VERIFY_INVALID_CREDENTIALS]: {
    errorCode: GLOBAL_ERRORS.ASSURANCE_VERIFY_INVALID_CREDENTIALS,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ASSURANCE_ID_NOT_FOUND]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ASSURANCE_NOT_STARTED]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ASSURANCE_NOT_STARTED_ERROR]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.AUTHENTICATE_IDP_DISABLED]: {
    errorCode: IDP_DISABLED,
    type: 'error',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.AUTHENTICATE_IDP_LOCKED]: {
    errorCode: IDP_LOCKED,
    type: 'error',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.FATAL]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ASSURANCE_FATAL]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ASSURANCE_USER_CANNOT_BE_SUPERVISOR]: {
    errorCode: GLOBAL_ERRORS.ASSURANCE_USER_CANNOT_BE_SUPERVISOR,
    type: 'error',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ASSURANCE_INVALID_GRANT]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ENROLL_ALREADY_EXISTS]: {
    errorCode: ENROLL_ALREADY_EXISTS,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ENROLL_PIN_ALREADY_EXISTS]: {
    errorCode: PIN_ALREADY_EXISTS,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ENROLL_INVALID_CREDENTIALS]: {
    errorCode: ENROLL_INVALID_CREDENTIALS,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ENROLL_ACTIVATE_FAILED]: {
    errorCode: ENROLL_ACTIVATE_FAILED,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.NOT_FOUND]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.PERMISSION_DENIED]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.IMPLEMENTATION_DEFECT]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INTERNAL]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INTERNAL_1]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INVALID_TENANT_ID]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INVALID_TENANT_STATE]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INCOMPLETE_OPERATION]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [SESSION_EXPIRED]: {
    errorCode: SESSION_EXPIRED,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.MISSING_TENANT_ID]: {
    errorCode: NO_TENANT_ID,
    type: 'error',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.PIN_CONSECUTIVE_DIGITS]: {
    errorCode: PIN_CONSECUTIVE_DIGITS,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.PIN_REPEATED_DIGITS]: {
    errorCode: PIN_REPEATED_DIGITS,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ENROLLED_BY_ANOTHER_USER]: {
    errorCode: ENROLLED_BY_ANOTHER_USER,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.PIN_VALUES_NOT_MATCHING]: {
    errorCode: PIN_VALUES_NOT_MATCHING,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
};
