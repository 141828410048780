import { BannerProps } from '@imprivata-cloud/components/lib/components/Banner/Banner';
export interface ErrorState {
  errorCode: string;
  errorMessageParams?: Record<string, string>;
}

export const enum ErrorType {
  GLOBAL = 'global',
  FULL_PAGE = 'full-page',
  VIEW = 'view',
  MODAL = 'modal',
}

export type ErrorConfig = {
  errorCode: string;
  message?: string;
  errorType: ErrorType;
  type: BannerProps['type'];
};

export type ErrorConfigMap = {
  [errorCode: string]: ErrorConfig;
};
