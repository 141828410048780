import { combineEpics, Epic } from 'redux-observable';
import {
  catchError,
  map,
  filter,
  switchMap,
  takeUntil,
  exhaustMap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { EMPTY, from, of, Subject, timer } from 'rxjs';
import { RootAction } from '../../../store/rootAction';
import {
  idProofingSessionActions,
  idProofingCompletedAction,
  goToNextStep,
  assuranceWorkflowSequenceError,
  individualAssuranceStartAction,
  verifyEmailAction,
  setAssuranceIdAction,
  sendOtpByEmailAction,
  setOtpIdAction,
  verifyEmailOtpAction,
  verifyPhoneOtpAction,
  wrongOtpAction,
  assuranceIdErrorAction,
  setAuthenticatorIdAction,
  verifyPhoneAction,
  sendOtpBySmsAction,
  individualAssuranceCompletedAction,
} from './actions';
import { History } from 'history';
import { AUTHENTICATORS_ROUTE } from '../../../routers/route-names';
import {
  checkIdProofingStatus$,
  completeIndividualAssurance,
  sendOtpByEmail,
  sendOtpBySms,
  startIdProofingSession,
  startIndividualAssurance,
  verifyOtp,
} from '../../../api/assuranceService';
import { ApiError, isErrorResponse } from '../../../api/types';
import { RootState } from '../../../store/rootReducer';
import { updateAssuranceType$ } from '../../../api/authenticatorsService';
import { AssuranceType } from '../../../models/models';
import { Banner } from '@imprivata-cloud/components';

const individualAssuranceStartEpic: Epic<RootAction> = (
  action$,
  state$,
  { history },
) =>
  action$.pipe(
    filter(isActionOf(individualAssuranceStartAction.request)),
    switchMap(() =>
      from(
        // TODO handle null case
        startIndividualAssurance(
          state$.value.assuranceWorkflow.authenticatorId,
        ),
      ).pipe(
        switchMap(res => {
          if (isErrorResponse(res)) {
            return of(individualAssuranceStartAction.failure(res.error));
          }
          if ('data' in res) res = res.data;
          return from([setAssuranceIdAction(res.assuranceId), goToNextStep()]);
        }),
      ),
    ),
  );

const verifyEmailEpic: Epic<RootAction> = (action$, state$, { history }) =>
  action$.pipe(
    filter(isActionOf(verifyEmailAction)),
    map(() => {
      return sendOtpByEmailAction.request();
    }),
  );

const verifyPhoneEpic: Epic<RootAction> = (action$, state$, { history }) =>
  action$.pipe(
    filter(isActionOf(verifyPhoneAction)),
    map(() => {
      return sendOtpBySmsAction.request();
    }),
  );

const sendOtpByEmailEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(sendOtpByEmailAction.request)),
    switchMap(() => {
      if (!state$.value.assuranceWorkflow.assuranceId) {
        return from([assuranceIdErrorAction(), sendOtpByEmailAction.cancel()]);
      }
      if (state$.value.login.user === undefined) {
        return of(sendOtpByEmailAction.cancel());
      }

      return from(
        sendOtpByEmail({
          assuranceId: state$.value.assuranceWorkflow.assuranceId,
          emailAddress: state$.value.login.user.email,
        }),
      ).pipe(
        switchMap(res => {
          if (isErrorResponse(res)) {
            return of(
              assuranceIdErrorAction(),
              sendOtpByEmailAction.failure(res.error),
            );
          }

          if ('data' in res) res = res.data;

          return of(setOtpIdAction(res.otpId));
        }),
      );
    }),
  );

const sendOtpBySmsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(sendOtpBySmsAction.request)),
    switchMap(() => {
      if (state$.value.assuranceWorkflow.assuranceId === null) {
        return from([assuranceIdErrorAction(), sendOtpBySmsAction.cancel()]);
      }
      if (state$.value.login.user === undefined) {
        alert('No user data found');
        return of(sendOtpBySmsAction.cancel());
      }

      return from(
        sendOtpBySms({
          assuranceId: state$.value.assuranceWorkflow.assuranceId,
          mobilePhone: state$.value.login.user.mobilePhone,
        }),
      ).pipe(
        switchMap(res => {
          if (isErrorResponse(res)) {
            return of(
              assuranceIdErrorAction(),
              sendOtpBySmsAction.failure(res.error),
            );
          }
          if ('data' in res) res = res.data;
          return of(setOtpIdAction(res.otpId));
        }),
      );
    }),
  );

// seperate epic for phone amd email because we redirect to different route
const verifyEmailOtpEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(verifyEmailOtpAction.request)),
    switchMap(({ payload: otp }) => {
      if (state$.value.assuranceWorkflow.assuranceId === null) {
        return from([assuranceIdErrorAction(), verifyEmailOtpAction.cancel()]);
      }

      if (!state$.value.assuranceWorkflow.otpId) {
        // TODO what happens when otpId is missing?
        return from([verifyEmailOtpAction.cancel()]);
      }

      return from(
        verifyOtp({
          assuranceId: state$.value.assuranceWorkflow.assuranceId,
          otpId: state$.value.assuranceWorkflow.otpId,
          otp,
        }),
      ).pipe(
        switchMap(res => {
          if (isErrorResponse(res)) {
            return of(wrongOtpAction());
          }
          return of(goToNextStep(), setOtpIdAction(null));
        }),
        catchError((_res: ApiError) => of(wrongOtpAction())),
      );
    }),
  );

const verifyPhoneOtpEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(verifyPhoneOtpAction.request)),
    switchMap(({ payload: otp }) => {
      if (state$.value.assuranceWorkflow.assuranceId === null) {
        return from([assuranceIdErrorAction(), verifyPhoneOtpAction.cancel()]);
      }

      if (state$.value.assuranceWorkflow.otpId === null) {
        // TODO what happens when otpId is missing?
        return from([verifyPhoneOtpAction.cancel()]);
      }

      return from(
        verifyOtp({
          assuranceId: state$.value.assuranceWorkflow.assuranceId,
          otpId: state$.value.assuranceWorkflow.otpId,
          otp,
        }),
      ).pipe(
        switchMap(res => {
          if (isErrorResponse(res)) {
            return of(wrongOtpAction());
          }
          return of(goToNextStep(), setOtpIdAction(null));
        }),
        catchError((_res: ApiError) => of(wrongOtpAction())),
      );
    }),
  );

const individualAssuranceCompletedEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$, { history }) =>
  action$.pipe(
    filter(isActionOf(individualAssuranceCompletedAction)),
    switchMap(() => {
      if (!state$.value.assuranceWorkflow.assuranceId)
        return of(assuranceIdErrorAction());

      return from(
        completeIndividualAssurance(state$.value.assuranceWorkflow.assuranceId),
      ).pipe(
        switchMap(res => {
          if (res.error) {
            Banner({
              type: 'error',
              message: 'Assurance Completion Failed',
              duration: 5,
              datatestid: 'assurance-completion-error',
            });
            history.replace(AUTHENTICATORS_ROUTE + window.location.search);
            return from(EMPTY);
          }
          return of(setAssuranceIdAction(null), setAuthenticatorIdAction(null));
        }),
      );
    }),
  );

const idProofingEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
  { history }: { history: History },
) =>
  action$.pipe(
    filter(isActionOf(idProofingSessionActions.request)),
    switchMap(() => {
      if (!state$.value.assuranceWorkflow.assuranceId)
        return of(assuranceIdErrorAction());
      return from(
        startIdProofingSession(state$.value.assuranceWorkflow.assuranceId),
      ).pipe(
        map(res => {
          if (isErrorResponse(res)) {
            return idProofingSessionActions.failure({
              code: res.error.code,
              message: res.error.message,
              data: res.error.data,
            });
          }
          if ('data' in res) res = res.data;

          return idProofingSessionActions.success(res.idProofingSessionToken);
        }),
        catchError((err: ApiError) =>
          // TODO error case not handled
          of(
            idProofingSessionActions.failure({
              code: err.code,
              message: err.message,
              data: err.data,
            }),
          ),
        ),
        takeUntil(
          action$.pipe(filter(isActionOf(idProofingSessionActions.cancel))),
        ),
      );
    }),
  );

const checkIdProofingStatusEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
  _,
) =>
  action$.pipe(
    filter(isActionOf(idProofingSessionActions.success)),
    switchMap(({ payload }) => {
      const assuranceId = state$.value.assuranceWorkflow.assuranceId;
      if (!assuranceId) return of(assuranceIdErrorAction());

      const stopPolling = new Subject();
      return timer(0, 2000).pipe(
        takeUntil(stopPolling),
        exhaustMap(() =>
          checkIdProofingStatus$({
            idProofingSessionToken: payload,
            assuranceId,
          }).pipe(
            filter(res => {
              // what happens when the request itself fails? when do we stop?
              if (isErrorResponse(res)) return false;
              if ('data' in res) res = res.data;
              // TODO move "DONE" value to const type
              if (res.idProofingStatusType.toUpperCase() === 'DONE')
                return true;
              return false;
            }),
            map(() => {
              stopPolling.next();
              return idProofingCompletedAction();
            }),
          ),
        ),
      );
    }),
  );

const changeAuthenticatorStatusEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(idProofingCompletedAction)),
    switchMap(() => {
      if (!state$.value.assuranceWorkflow.authenticatorId)
        return of(assuranceWorkflowSequenceError());

      return updateAssuranceType$({
        assuranceType: AssuranceType.IAL2_ASSURED,
        authenticatorId: state$.value.assuranceWorkflow.authenticatorId,
      }).pipe(
        map(_res => {
          // TODO handle error case
          return goToNextStep();
        }),
      );
    }),
  );

export const individualAssuranceEpics: Epic = combineEpics(
  individualAssuranceStartEpic,
  idProofingEpic,
  checkIdProofingStatusEpic,
  changeAuthenticatorStatusEpic,
  individualAssuranceCompletedEpic,
  verifyEmailEpic,
  verifyPhoneEpic,
  sendOtpByEmailEpic,
  verifyEmailOtpEpic,
  sendOtpBySmsEpic,
  verifyPhoneOtpEpic,
);
