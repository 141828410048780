import { Form } from 'antd';
import { InputBox } from '@imprivata-cloud/components';
import { useTranslation } from 'react-i18next';

const TokenInput: React.FC<{
  width: string /** style width. e.g. "20px" */;
  label?: string;
  tokenInputAutofocus?: boolean;
}> = ({ width, label, tokenInputAutofocus }) => {
  const { t } = useTranslation();

  const onKeyPress = (e: React.KeyboardEvent) => {
    const specialCharRegex = new RegExp('[0-9]');
    if (
      !specialCharRegex.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Enter' &&
      e.key !== 'Tab'
    ) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <Form.Item
      data-testid="token-value"
      name="tokenValue"
      label={label || t('assurance.verification.impr-id.token-code')}
    >
      <InputBox
        autoFocus={tokenInputAutofocus}
        onKeyDown={onKeyPress}
        data-testid="totp-input"
        maxLength={6}
        placeholder=" "
        style={{
          width: `${width}`,
        }}
        size="small"
        type="text"
      />
    </Form.Item>
  );
};

export default TokenInput;
