// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.less';
import SetTitle from '../../utils/DynamicTitleHelper';
import { Col, Row } from 'antd';
import { Button, ButtonSize, ButtonVariant } from '@imprivata-cloud/components';
import AuthenticatorList from './AuthenticatorList';
import {
  ENROLL_AUTHENTICATOR_ROUTE,
  IID_ENROLL_ROUTE,
} from '../../routers/route-names';
import { useQueryParams, useStartGlobalUiIdleTimeout } from '../../utils/hooks';
import { QueryParamKey } from './store/constants';
import { useLoginHook } from '../login/store/hooks';
import { redirectWithQuery } from '../../utils/routingHelpers';
import AssuranceWizardLayout from '../../components/layout/AssuranceWizardLayout';
import { useGetAuthenticators } from './store/hooks';
import { useDispatch, useSelector } from 'react-redux';
import getIcon from '../../assets/svg/add.svg?react';
import Icon from '@ant-design/icons';
import { clearErrorsAction } from '../login/store/actions';
import EnrollAuthenticator from './EnrollAuthenticator';
import getConfig from '../../appConfigUtils';
import { userFirstNameSelector } from '../login/store/selectors';

const { PIN_DISABLED } = getConfig();

const AuthenticatorManagement: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { userId } = useLoginHook();
  const dispatch = useDispatch();

  useEffect(() => {
    const tenantId = params.get(QueryParamKey.TENANT_ID);
    if (!tenantId || !userId) {
      // clear state and redirect to login page.
      dispatch(clearErrorsAction());
    }
  }, [dispatch, params, userId]);

  const { startTimer } = useStartGlobalUiIdleTimeout();
  startTimer();

  const { authenticators } = useGetAuthenticators(false);
  const username = useSelector(userFirstNameSelector);

  const newHere = (
    <EnrollAuthenticator
      header={t('authenticators.none', { username })}
      subHeader={t('authenticators.none-instructions')}
      disableCancel
    />
  );

  const onAddAuthenticator = () => {
    if (PIN_DISABLED) {
      redirectWithQuery(IID_ENROLL_ROUTE);
    } else {
      redirectWithQuery(ENROLL_AUTHENTICATOR_ROUTE);
    }
  };

  const hasAuths = (
    <AssuranceWizardLayout>
      <div>
        <Row className={classes.listHeader} align="middle">
          <Col span={6} className={classes.addBtn} offset={17}>
            <Button
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MINOR}
              label={t('authenticators.enroll-another-method')}
              data-testid="enroll-button"
              onClick={onAddAuthenticator}
              disabled={false}
              icon={<Icon component={getIcon} />}
            />
          </Col>
        </Row>
        <Row className={classes.listHeader} align="middle">
          <Col span={17}>
            <div className={classes.header}>
              {t('authenticators.headers.manage')}
            </div>
          </Col>
        </Row>
        <Row style={{ maxHeight: '450px', overflow: 'auto' }}>
          {userId && <AuthenticatorList authenticators={authenticators} />}
        </Row>
      </div>
    </AssuranceWizardLayout>
  );

  let toDisplay = <></>;

  if (authenticators) {
    if (authenticators.length === 0) {
      toDisplay = newHere;
    } else {
      toDisplay = hasAuths;
    }
  }

  SetTitle(t('navigation.authenticator-list'));
  return toDisplay;
};

export default AuthenticatorManagement;
