// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { ReactElement } from 'react';
import {
  Link,
  useLocation,
  Route,
  LinkProps,
  RouteProps,
  RedirectProps,
  Redirect,
} from 'react-router-dom';
import history from '../routers/history';

export type LinkWithQueryProps = LinkProps;

export const LinkWithQuery = ({
  children,
  to,
  ...props
}: LinkWithQueryProps): ReactElement<LinkProps> => {
  const { search } = useLocation();

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
};

export type RouteWithQueryProps = RouteProps;

export const RouteWithQuery = (
  props: RouteWithQueryProps,
): ReactElement<RouteProps> => {
  const { search } = useLocation();

  return <Route path={props.path + search} {...props} />;
};

export const RedirectWithQuery: React.FC<
  RedirectProps & { to: string }
> = props => {
  const { search } = useLocation();
  const currentSearchParams = new URLSearchParams(search);
  const redirectUrl = new URL(props.to, window.location.origin);

  const mergedSearchParams = new URLSearchParams({
    ...Object.fromEntries(currentSearchParams),
    ...Object.fromEntries(redirectUrl.searchParams),
  });

  return (
    <Redirect
      {...props}
      to={`${redirectUrl.pathname}?${mergedSearchParams.toString()}`}
    />
  );
};

export function redirectWithQuery(
  path: string,
  additionalParams?: string,
): void {
  history.push(path + window.location.search + (additionalParams || ''));
}
