import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deleteHeader } from './api';
import { client, fetchWithKeepAlive } from './client';
import { assurance } from './endpoint-names';
import { Headers } from './constants';
import rxClient from './rxClient';
import {
  ApiResponse,
  CheckIdProofingStatusResponse,
  IdProofingStartSessionResponse,
  VerifyAuthenticatorRequest,
} from './types';

export function startIndividualAssurance(authenticatorId: string): Promise<
  ApiResponse<{
    assuranceId: string;
  }>
> {
  return client
    .post(assurance.START_INDIVIDUAL_ASSURANCE, { authenticatorId })
    .then(res => res.data);
}

export function startSupervisedAssurance(authenticatorId: string): Promise<
  ApiResponse<{
    assuranceId: string;
  }>
> {
  return client
    .post(assurance.START_SUPERVISED_ASSURANCE, { authenticatorId })
    .then(res => res.data);
}

export function completeIndividualAssurance(
  assuranceId: string,
): Promise<ApiResponse<void>> {
  return client
    .post(assurance.COMPLETE_INDIVIDUAL_ASSURANCE, { assuranceId })
    .then(res => res.data);
}

export type SupervisedAssuranceCompleteRequest = {
  assuranceId: string;
  oidcAuthorizationCode: string;
  oidcCodeVerifier: string;
};

export type CancelSupervisedAssuranceRequest = {
  assuranceId: string;
};

export function completeSupervisedAssurance(
  payload: SupervisedAssuranceCompleteRequest,
): Promise<ApiResponse<void>> {
  deleteHeader(Headers.ImprInAppSession);
  return client
    .post(assurance.COMPLETE_SUPERVISED_ASSURANCE, payload)
    .then(res => res.data);
}

export function cancelSupervisedAssurance(
  payload: CancelSupervisedAssuranceRequest,
): Promise<ApiResponse<void>> {
  return fetchWithKeepAlive
    .client(assurance.CANCEL_SUPERVISED_ASSURANCE, payload, [
      Headers.ImprInAppSession,
    ])
    .then(async r => {
      if (r.ok) {
        return Promise.resolve();
      } else {
        const error = await r.json();
        return Promise.reject(error.error);
      }
    });
}

export function sendOtpByEmail({
  assuranceId,
  emailAddress,
}: {
  assuranceId: string;
  emailAddress: string;
}): Promise<ApiResponse<{ otpId: string }>> {
  return client
    .post(assurance.SEND_OTP_BY_EMAIL, { assuranceId, emailAddress })
    .then(res => res.data);
}

export function sendOtpBySms({
  assuranceId,
  mobilePhone,
}: {
  assuranceId: string;
  mobilePhone: string;
}): Promise<ApiResponse<{ otpId: string }>> {
  return client
    .post(assurance.SEND_OTP_BY_SMS, { assuranceId, mobilePhone })
    .then(res => res.data);
}

export function verifyOtp({
  assuranceId,
  otpId,
  otp,
}: {
  assuranceId: string;
  otpId: string;
  otp: string;
}): Promise<ApiResponse<void>> {
  return client
    .post(assurance.VERIFY_OTP, { assuranceId, otpId, otp })
    .then(res => res.data);
}

export function verifyIdentity({
  assuranceId,
  idDocuments,
  comments,
}: {
  assuranceId: string;
  idDocuments: string[];
  comments: string;
}): Promise<ApiResponse<void>> {
  return client.post(assurance.VERIFY_IDENTITY, {
    assuranceId,
    idDocuments,
    comments,
  });
}

export function verifyAuthenticator(
  payload: VerifyAuthenticatorRequest,
): Promise<ApiResponse<void>> {
  return client
    .post(assurance.VERIFY_AUTHENTICATOR, payload)
    .then(res => res.data);
}

export function startIdProofingSession(
  assuranceId: string,
): Promise<ApiResponse<IdProofingStartSessionResponse>> {
  return client
    .post(assurance.ID_PROOFING_START_SESSION, { assuranceId })
    .then(res => res.data);
}

export function checkIdProofingStatus$({
  idProofingSessionToken,
  assuranceId,
}: {
  idProofingSessionToken: string;
  assuranceId: string;
}): Observable<ApiResponse<CheckIdProofingStatusResponse>> {
  return rxClient
    .post<ApiResponse<CheckIdProofingStatusResponse>>(
      assurance.ID_PROOFING_CHECK_STATUS,
      {
        idProofingSessionToken,
        assuranceId,
      },
    )
    .pipe(map(res => res.data));
}
