import { FactorTypeEnum } from '../../../models/models';

export enum ErrorMessages {
  'AUTHENTICATION_FAILED' = 'Authentication failed',
  'INCORRECT_TENANT_ID' = 'The Tenant ID is not valid. Please see your administrator.',
  'TENANT_ID_NOT_PROVIDED' = 'No Tenant ID provided.',
  'USER_ID_NOT_PROVIDED' = 'User ID not provided.',
  'SESSION_EXPIRED' = 'Session expired',
}

export const ENROLLED_BY_ANOTHER = 'enrolled-by-another';

export const PIN_VALUES_NOT_MATCHING = 'pin-values-not-matching';

export const AUTHENTICATOR_INSTANCE_SINGLE = 'single';

export const AUTHENTICATOR_INSTANCE_MULTIPLE = 'multiple';

export const SINGLE_INSTANCE_AUTHENTICATORS = [FactorTypeEnum.IMPRIVATA_PIN];

// query parameter keys
export const QueryParamKey = {
  TENANT_ID: 'tenantId',
};
