// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const SpanNames = {
  INIT_APP: 'init_app' as const,
  AUTHENTICATION: 'authentication' as const,

  CREATE_CLIENT_SESSION: 'create_client_session' as const,

  AUTHENTICATOR_ASSURANCE_START: 'authenticator_assurance_start' as const,
  AUTHENTICATOR_ASSURANCE_VERIFY_IDENTITY:
    'authenticator_assurance_verify_identity' as const,
  AUTHENTICATOR_ASSURANCE_VERIFY_IMPRID:
    'authenticator_assurance_verify_imprid' as const,
};
