// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import {
  enrollAuthenticatorActions,
  listAuthenticatorsActions,
  unenrollAuthenticatorActions,
} from './actions';
import { RootAction } from '../../../store/rootAction';
import { Authenticator } from '../../../models/models';
import { logoutAction } from '../../login/store/actions';

export interface ListAuthenticatorsState {
  loading: boolean;
  error: string | null;
  authenticators: Authenticator[] | null;
  selectedAuthenticator?: Authenticator;
}

export interface EnrollAuthenticatorState {
  requested: boolean;
  error: string | null;
  completed: boolean;
}

export interface UnenrollAuthenticatorState {
  requested: boolean;
  error: string | null;
  completed: boolean;
}

export interface AuthenticatorsState {
  enrollAuthenticator: EnrollAuthenticatorState;
  unenrollAuthenticator: UnenrollAuthenticatorState;
  listAuthenticators: ListAuthenticatorsState;
}

export const initialState: AuthenticatorsState = {
  enrollAuthenticator: {
    requested: false,
    completed: false,
    error: null,
  },
  unenrollAuthenticator: {
    requested: false,
    error: null,
    completed: false,
  },
  listAuthenticators: {
    loading: false,
    error: null,
    authenticators: null,
  },
};

export const listAuthenticatorsReducer =
  combineReducers<ListAuthenticatorsState>({
    loading: createReducer<boolean>(initialState.listAuthenticators.loading)
      .handleAction([listAuthenticatorsActions.request], () => true)
      .handleAction(
        [
          listAuthenticatorsActions.cancel,
          listAuthenticatorsActions.success,
          listAuthenticatorsActions.failure,
        ],
        () => false,
      ),
    authenticators: createReducer<Authenticator[] | null, RootAction>(
      initialState.listAuthenticators.authenticators,
    )
      .handleAction(
        listAuthenticatorsActions.success,
        (_, { payload }) => payload.authenticators,
      )
      .handleAction(logoutAction.success, _state => null),
    error: createReducer<string | null, RootAction>(
      initialState.listAuthenticators.error,
    )
      .handleAction(
        [listAuthenticatorsActions.request, listAuthenticatorsActions.success],
        () => null,
      )
      .handleAction(
        listAuthenticatorsActions.failure,
        (state, { payload }) => payload.code || null,
      )
      .handleAction(listAuthenticatorsActions.cancel, () => 'cancelled'),
  });

export const enrollAuthenticatorReducer =
  combineReducers<EnrollAuthenticatorState>({
    requested: createReducer<boolean>(
      initialState.enrollAuthenticator.requested,
    )
      .handleAction([enrollAuthenticatorActions.request], () => true)
      .handleAction(
        [
          enrollAuthenticatorActions.cancel,
          enrollAuthenticatorActions.success,
          enrollAuthenticatorActions.failure,
        ],
        () => false,
      ),
    completed: createReducer<boolean, RootAction>(
      initialState.enrollAuthenticator.completed,
    )
      .handleAction([enrollAuthenticatorActions.success], () => true)
      .handleAction(
        [
          enrollAuthenticatorActions.cancel,
          enrollAuthenticatorActions.request,
          enrollAuthenticatorActions.failure,
        ],
        () => false,
      ),
    error: createReducer<string | null, RootAction>(
      initialState.enrollAuthenticator.error,
    )
      .handleAction(
        [
          enrollAuthenticatorActions.request,
          enrollAuthenticatorActions.success,
        ],
        () => null,
      )
      .handleAction(
        enrollAuthenticatorActions.failure,
        (state, { payload }) => payload.code || null,
      )
      .handleAction(enrollAuthenticatorActions.cancel, () => 'cancelled'),
  });

export const unenrollAuthenticatorReducer =
  combineReducers<UnenrollAuthenticatorState>({
    requested: createReducer<boolean>(
      initialState.unenrollAuthenticator.requested,
    )
      .handleAction([unenrollAuthenticatorActions.request], () => true)
      .handleAction(
        [
          unenrollAuthenticatorActions.cancel,
          unenrollAuthenticatorActions.success,
          unenrollAuthenticatorActions.failure,
        ],
        () => false,
      ),
    completed: createReducer<boolean, RootAction>(
      initialState.unenrollAuthenticator.completed,
    )
      .handleAction([unenrollAuthenticatorActions.success], () => true)
      .handleAction(
        [
          unenrollAuthenticatorActions.cancel,
          unenrollAuthenticatorActions.request,
          unenrollAuthenticatorActions.failure,
        ],
        () => false,
      ),
    error: createReducer<string | null, RootAction>(
      initialState.unenrollAuthenticator.error,
    )
      .handleAction(
        [
          unenrollAuthenticatorActions.request,
          unenrollAuthenticatorActions.success,
        ],
        () => null,
      )
      .handleAction(
        unenrollAuthenticatorActions.failure,
        (state, { payload }) => payload.code || null,
      )
      .handleAction(unenrollAuthenticatorActions.cancel, () => 'cancelled'),
  });

export const authenticatorsReducer = combineReducers<AuthenticatorsState>({
  enrollAuthenticator: enrollAuthenticatorReducer,
  unenrollAuthenticator: unenrollAuthenticatorReducer,
  listAuthenticators: listAuthenticatorsReducer,
});
