// Copyright 2022, Imprivata, Inc.  All rights reserved.

import translations from './translations/en.json';
import deviceModelTranslations from './translations/deviceModel.en.json';

export const defaultNS = 'translation' as const;
export const deviceModelNS = 'deviceModel' as const;

export const ns = [defaultNS, deviceModelNS];
export const resources = {
  en: {
    [defaultNS]: translations,
    [deviceModelNS]: deviceModelTranslations,
  },
} as const;

export enum ContextNames {
  ASSURANCE = 'assurance',
  AUTHENTICATORS = 'authenticators',
  LOGIN = 'login',
  SUPERVISOR = 'supervisor',
  IID_ENROLLMENT = 'iid-enrollment',
  PIN_ENROLLMENT = 'pin-enrollment',
}
