import { Epic, combineEpics } from 'redux-observable';
import { filter, ignoreElements, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootAction } from '../../../store/rootAction';
import { RootState } from '../../../store/rootReducer';
import { endWorkflowAction, startWorkflowAction } from './actions';
import { deleteHeader, setHeader } from '../../../api/api';
import { Headers } from '../../../api/constants';
import { tracer } from '../../../tracing';

export const startWorkflowEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(startWorkflowAction)),
    tap(({ payload }) => {
      setHeader(Headers.ImprWorkflowId, payload);
      tracer.setWorkflowId(payload);
    }),
    ignoreElements(),
  );

export const endWorkflowEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(endWorkflowAction)),
    tap(() => {
      deleteHeader(Headers.ImprWorkflowId);
      tracer.setWorkflowId('');
    }),
    ignoreElements(),
  );

export const workflowEpic = combineEpics(startWorkflowEpic, endWorkflowEpic);
