import { RootState } from '../../../store/rootReducer';
import { LoginState } from './reducers';

export const loginSelector = ({ login }: RootState): LoginState => login;

export const loginSelector2 = ({
  login: { sessionErrorCode, user },
}: RootState): {
  userId?: string;
  sessionErrorCode: LoginState['sessionErrorCode'];
} => ({
  userId: user?.userId,
  sessionErrorCode,
});

export const userDisplayNameSelector = ({
  login,
}: RootState): string | undefined => login.user?.displayName;

export const userFirstNameSelector = ({
  login,
}: RootState): string | undefined => login.user?.firstName;

export const userEmailSelector = ({ login }: RootState): string | undefined =>
  login.user?.email;
