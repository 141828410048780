export const UNEXPECTED_ERROR_MESSAGE = 'An unexpected problem occurred.';
export const UNEXPECTED_ERROR_CODE = 'errors.fatal';

export const IDP_DISABLED = 'errors.account-disabled';

export const IDP_LOCKED = 'errors.account-locked';

export const ENROLL_ALREADY_EXISTS = 'errors.enroll-already-exists';
export const PIN_ALREADY_EXISTS = 'errors.pin-already-exists';

export const ENROLL_INVALID_CREDENTIALS = 'errors.enroll-invalid-credentials';

export const ENROLL_ACTIVATE_FAILED = 'errors.enroll-activate-failed';

export const SESSION_EXPIRED = 'errors.session-expired';

export const NO_TENANT_ID = 'errors.tenant-id-not-provided';

export const PIN_CONSECUTIVE_DIGITS = 'errors.pin-consecutive-digits';

export const PIN_REPEATED_DIGITS = 'errors.pin-repeated-digits';

export const ENROLLED_BY_ANOTHER_USER = 'errors.enrolled-by-another';

export const PIN_VALUES_NOT_MATCHING = 'errors.pin-values-not-matching';

export enum ERROR_CODES {
  FATAL = 'fatal',
  PERMISSION_DENIED = 'forbidden',
  SESSION_EXPIRED = 'session-expired',
  INVALID_SESSION_ID = 'invalid-session-id',
  SESSION_NOT_FOUND = 'session-not-found',
  IMPLEMENTATION_DEFECT = 'implementation-defect',
  INTERNAL = 'internal',
  INTERNAL_1 = 'INTERNAL',
  INVALID_TENANT_ID = 'invalid-tenant-id',
  INVALID_TENANT_STATE = 'invalid-tenant-state',
  INCOMPLETE_OPERATION = 'incomplete-operation',
  MISSING_TENANT_ID = 'missing-tenant-id',
  ASSURANCE_NOT_STARTED = 'assurance-not-started',
}

export enum SESSION_ERROR_CODES {
  SESSION_EXPIRED = 'session-expired',
  INVALID_SESSION_ID = 'invalid-session-id',
  SESSION_NOT_FOUND = 'session-not-found',
}
