import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from './routers/history';
import { configureStore } from './store/createStore';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { LocaleProvider } from './i18n/localeProvider';
import { applyInterceptors } from './api/interceptors';
import { rehydrate } from './containers/login/store/reducers';
import { startInitAppTracingSpan } from './containers/tracing';

import './styles/index.less';
import { ErrorHandler } from './error-handler/ErrorHandler';

const store = configureStore({
  isProduction: process.env.NODE_ENV === 'production',
  history,
  initialState: { login: rehydrate() },
});
applyInterceptors(store.dispatch);

startInitAppTracingSpan();

ReactDOM.render(
  <Provider store={store}>
    <LocaleProvider>
      <Router history={history}>
        <ErrorHandler>
          <App />
        </ErrorHandler>
      </Router>
    </LocaleProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
