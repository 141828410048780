// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const HOME_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const AUTHENTICATORS_ROUTE = '/authenticators';
export const IID_ENROLL_ROUTE = '/authenticators/enroll/iid';
export const PIN_ENROLL_ROUTE = '/authenticators/enroll/pin';
export const ENROLL_AUTHENTICATOR_ROUTE = '/authenticators/enroll';
export const ASSURE_SUPERVISOR = '/assure/supervisor';
export const NOT_FOUND_ROUTE = '/not-found';
