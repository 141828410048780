// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Tracer } from '@imprivata-cloud/common';
import getConfig from '../appConfigUtils';
export * from './constants';

const { OTLP_TRACING_URL, ZIPKIN_TRACING_URL, COLLECT_TRACING } = getConfig();

export const tracer = new Tracer('user-web-ui', '', {
  logToConsole: true,
  otlpExportUrl: OTLP_TRACING_URL,
  zipkinExportUrl: ZIPKIN_TRACING_URL,
  collectSpans: !!COLLECT_TRACING,
});
