// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import classes from './ChooseAuth.module.less';
import { FactorTypeEnum } from '../../models/models';
import { redirectWithQuery } from '../../utils/routingHelpers';
import { AUTHENTICATORS_ROUTE } from '../../routers/route-names';
import { Col, Row } from 'antd';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import { useTranslation } from 'react-i18next';
import getIcon from '../../assets/svg/add_blue.svg?react';
import Icon from '@ant-design/icons';
import clsx from 'clsx';
import { getFactorTypeIcon } from '../../utils/IconUtil';

interface Props {
  label: string;
  enabled: boolean;
  factor: FactorTypeEnum;
  imgWidth: string;
  enrollRoute?: string;
}

const AuthBox: React.FC<Props> = ({
  label,
  enabled,
  factor,
  imgWidth,
  enrollRoute,
}) => {
  const lineStyle = enabled
    ? `${classes.enabledLine}`
    : `${classes.disabledLine}`;
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <div
          className={clsx(classes.imgBox, !enabled && classes.disabledBgColor)}
        >
          {getFactorTypeIcon(factor, 'white', {
            height: '32px',
            width: imgWidth,
            marginBottom: '8px',
          })}
        </div>
      </Col>
      <Col span={16}>
        <Row
          className={clsx(
            classes.authHeader,
            !enabled && classes.disabledFgColor,
          )}
        >
          <span>{label}</span>
        </Row>

        <Row className={clsx(lineStyle, !enabled && classes.disabledFgColor)} />
        {enabled && (
          <Row className={classes.addRow}>
            <Button
              type="text"
              variant={ButtonVariant.LINK}
              label={t('authenticators.add') as string}
              data-testid={`${factor}-add-btn`}
              icon={<Icon component={getIcon} />}
              onClick={() =>
                redirectWithQuery(enrollRoute ?? AUTHENTICATORS_ROUTE)
              }
            />
          </Row>
        )}
        {!enabled && (
          <Row className={classes.notSupported} data-testid="disabled">
            {factor !== FactorTypeEnum.IMPRIVATA_PIN &&
              t('authenticators.no-support')}
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default AuthBox;
