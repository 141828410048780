// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Secret } from '@imprivata-cloud/data-privacy-js';
import { User } from '../containers/login/store/reducers';
import { AssuranceType, Authenticator, FactorTypeEnum } from '../models/models';

// TODO: should probably migrate all of this into the models folder somewhere:
export interface ApiError {
  code: string;
  type?: string;
  message?: string;
  data?: unknown;
}

export type SuccessResponse<T> = T;

export type ErrorResponse = {
  error: ApiError;
};

// Type Guard needed to access success response data
export const isErrorResponse = (
  value: ApiResponse<unknown>,
): value is ErrorResponse => {
  if (typeof value === 'object' && value !== null) {
    return 'error' in value && !('data' in value);
  }
  return false;
};

export type ApiResponse<T> = SuccessResponse<T> | ErrorResponse;

export interface AuthenticatorsPayload {
  authenticators: Authenticator[];
}

export interface AuthenticatorsRequest {
  tenantId: string;
}

export type FactorData = {
  version: number;
  username: string;
  password: string;
};

export interface DeviceData {
  phoneData: string;
}

// Proof Key for Code Exchange
export interface PKCECodes {
  codeVerifier: string;
  codeChallenge: string;
}

export interface SystemData {
  browserData: string;
}

export type AuthenicatorEnrollResponse = ApiResponse<void>;

export type ValidatePermissionPayload = Record<string, unknown>;

export interface AuthenticatorEnrollRequest {
  factorType: string;
  factorData: string | Secret;
  deviceData: string; // base64 encoded
  systemData: string | Secret;
}

export type CreateClientSessionPayload = {
  userId?: string;
  user: User;
};

export type AuthenticatePayload = {
  credentialsData?: Secret /** @description decrypt(credentialsData) = { saml: string}. will be depricated in v4 */;
  oidcAuthorizationCode?: string;
  userKnown?: boolean;
  nextFactors?: FactorOption[];
};

export type AuthenicatorUnenrollResponse = ApiResponse<void>;

export type AssuranceTypeUpdateRequest = {
  assuranceType: AssuranceType;
  authenticatorId: string;
};

export interface AuthenticatorUnenrollRequest {
  authenticatorId: string;
}

export interface IdProofingStartSessionResponse {
  idProofingSessionToken: string;
  url: string;
}

export interface CheckIdProofingStatusResponse {
  idProofingStatusType: string;
}

export interface VerifyAuthenticatorRequest {
  assuranceId: string;
  authenticatorId: string;
  factorType: FactorTypeEnum;
  factorData: Secret;
  systemData?: Secret;
}

enum FactorCategoryEnum {
  KNOW = 'know',
  HAVE = 'have',
  ARE = 'are',
}

export type GetNextFactorsRequest = {
  authnContext: {
    contextType: string;
    resourceType: string;
  };
  availableFactorTypes: FactorTypeEnum[];
  username?: string;
};

type FactorOption = {
  factorType: FactorTypeEnum;
  factorCategory: FactorCategoryEnum;
  pushable?: boolean;
};

export type GetNextFactorsPayload = {
  nextFactors: FactorOption[];
};

export type ModelData = {
  deviceModel: string;
  lastUsedAt: string; // ISO format
};

export type PinSettingsResponse = {
  minLength: number;
  maxLength: number;
  allowNonNumeric: boolean;
};
