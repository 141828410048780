import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useLocation, useHistory, Prompt } from 'react-router-dom';
import { Location } from 'history';
import classes from './ConfirmationModal.module.less';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import QuestionSvg from '../../assets/svg/question.svg';

interface Props {
  title: string;
  content: string;
  visible: boolean;
  cancelText: string;
  okText: string;
  onDiscard: () => void;
  onConfirm: () => void;
  onClose?: () => void;
}

const ConfirmationModal: React.FC<Props> = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(props.visible);

  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation: Location) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation: Location) => {
    if (
      !confirmedNavigation &&
      props.visible &&
      history.location.pathname !== nextLocation.pathname
    ) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    props.onConfirm();
    setConfirmedNavigation(true);
  };

  const handleDiscardNavigationClick = (e: React.MouseEvent<HTMLElement>) => {
    // if clicked on the modal X icon just close the modal
    if (e.currentTarget.getAttribute('aria-label') === 'Close') {
      closeModal();
    } else {
      // discard button was clicked
      props.onDiscard();
      closeModal();
      setConfirmedNavigation(true);
    }
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname + lastLocation.search);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event: { returnValue: string }) => {
      if (props.visible && !shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = props.content;
      }
      if (shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [props.visible, props.content, shouldUnload]);
  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      {isModalOpen && (
        <Modal
          className={classes.modal}
          cancelText={props.cancelText}
          okText={props.okText}
          open={props.visible}
          data-testid="confirm-discard-modal"
          onCancel={handleDiscardNavigationClick}
          onOk={handleConfirmNavigationClick}
          footer={null}
          maskClosable={false}
        >
          <div className={classes.modalContentWraper}>
            <div>
              <img src={QuestionSvg} alt="Question Mark" />
              <div className={classes.modalTitle}>{props.title}</div>
              <div className={classes.modalContent}>{props.content}</div>
            </div>
            <div className={classes.modalFooterButtons}>
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={handleDiscardNavigationClick}
                label={props.cancelText}
                data-testid="confirmation-modal-cancel"
              />
              <Button
                variant="ghost"
                onClick={handleConfirmNavigationClick}
                label={props.okText}
                data-testid="confirmation-modal-ok"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ConfirmationModal;
