import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.less';
import { Row, Form, Checkbox, Input } from 'antd';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import { useDispatch, useSelector } from 'react-redux';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { verifyIdentity } from '../../api/assuranceService';
import {
  cancelSupervisedAssuranceAction,
  goToNextStep,
  verifyIdentityAction,
} from './store/actions';
import { RootState } from '../../store/rootReducer';
import AssuranceWizardLayout from '../../components/layout/AssuranceWizardLayout';
import { redirectWithQuery } from '../../utils/routingHelpers';
import { AUTHENTICATORS_ROUTE } from '../../routers/route-names';
import { addErrorHandlerAction } from '../../error-handler/store/action';
import { SpanNames, tracer } from '../../tracing';

const VerifyPhotoID: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [idDocuments, setIdDocuments] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { TextArea } = Input;

  const assuranceId =
    useSelector((state: RootState) => state.assuranceWorkflow.assuranceId) ||
    '';

  const onChange = (ids: CheckboxValueType[]) => {
    setSaveDisabled(ids.length === 0);
    setIdDocuments(ids as string[]);
  };

  const validateAndUpdate = () => {
    const comments = form.getFieldValue('comments');

    form.validateFields().then(_ => {
      tracer.startSpan(SpanNames.AUTHENTICATOR_ASSURANCE_VERIFY_IDENTITY);
      verifyIdentity({ assuranceId, comments, idDocuments })
        .then(_ => {
          dispatch(verifyIdentityAction.success());
          setSaveDisabled(true);
          tracer.endSpan(SpanNames.AUTHENTICATOR_ASSURANCE_VERIFY_IDENTITY);
        })
        .catch(err => {
          dispatch(addErrorHandlerAction({ errorCode: err.code }));
          tracer.endSpan(
            SpanNames.AUTHENTICATOR_ASSURANCE_VERIFY_IDENTITY,
            err,
          );
          redirectWithQuery(AUTHENTICATORS_ROUTE);
        });
    });
  };

  return (
    <AssuranceWizardLayout title={t('navigation.verifyid')}>
      <div className={classes.outerBox}>
        <div className={classes.authorized}>
          {t('assurance.verification.photo-id.supervisor-label')}
        </div>

        <div className={classes.headerSpacing}>
          <Row>
            <div className={classes.header}>
              {t('assurance.verification.header')}
            </div>
          </Row>
          <Row>
            <div className={classes.instructions}>
              {t('assurance.verification.photo-id.instructions')}
            </div>
          </Row>
          <Form form={form} layout="vertical" name="register">
            <Row>
              <Form.Item data-testid="choose-ids" name="idDocuments" label="">
                <div className={classes.medPaddedRow}>
                  <Checkbox.Group onChange={onChange}>
                    <Checkbox data-testid="state-id-choice" value="state-id">
                      {t('assurance.verification.photo-id.state-id')}
                    </Checkbox>
                    <Checkbox
                      data-testid="military-id-choice"
                      value="military-id"
                    >
                      {t('assurance.verification.photo-id.military-id')}
                    </Checkbox>
                    <Checkbox data-testid="passport-choice" value="passport">
                      {t('assurance.verification.photo-id.passport')}
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                data-testid="id-comments"
                name="comments"
                label={t('assurance.verification.comments')}
              >
                <div className={classes.details}>
                  <TextArea rows={3} cols={82} maxLength={255} />
                </div>
              </Form.Item>
            </Row>
          </Form>
          <Row className={classes.verifyIdentityRow}>
            <Button
              variant={ButtonVariant.PRIMARY}
              label={t('actions.continue')}
              data-testid="continue-button"
              onClick={() => {
                validateAndUpdate();
                dispatch(goToNextStep());
              }}
              disabled={isSaveDisabled}
              autoFocus={!isSaveDisabled}
            />
          </Row>
          <Row className={classes.verifyIdentityCancelRow}>
            <Button
              type="text"
              variant={ButtonVariant.TEXT}
              label={t('actions.later')}
              data-testid="cancel-button"
              onClick={() => {
                dispatch(
                  cancelSupervisedAssuranceAction.request({ assuranceId }),
                );
              }}
              disabled={false}
            />
          </Row>
        </div>
      </div>
    </AssuranceWizardLayout>
  );
};

export default VerifyPhotoID;
