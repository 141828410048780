import { Button, ButtonVariant } from '@imprivata-cloud/components';
import { Form, Row } from 'antd';
import React, { useState } from 'react';
import classes from './styles.module.less';
import {
  cancelSupervisedAssuranceAction,
  verifyAuthenticatorTotpAction,
} from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AssuranceWizardLayout from '../../components/layout/AssuranceWizardLayout';
import TokenInput from '../../components/assure/TokenInput';
import { RootState } from '../../store/rootReducer';
import { Authenticator } from '../../models/models';
import i18n from '../../i18n';

const VerifyImprId: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);

  const handleChange = () => {
    setSaveDisabled(false);
    form
      .validateFields()
      .then(values => {
        if (!values.tokenValue || values.tokenValue.length !== 6) {
          setSaveDisabled(true);
        }
      })
      .catch(errorInfo => {
        setSaveDisabled(true);
      });
  };

  const verify = () => {
    form.validateFields().then(values => {
      dispatch(verifyAuthenticatorTotpAction.request(values.tokenValue));
    });
    setSaveDisabled(true);
  };

  // TODO save Authenticator object in assurance/reducer instead of AuthenticatorId
  const currentAuthenticators = useSelector<RootState, Authenticator[]>(
    state =>
      state.authenticatorManagement.listAuthenticators.authenticators || [],
  );
  const currentAssuringAuthenticatorId = useSelector<RootState>(
    state => state.assuranceWorkflow.authenticatorId,
  );
  let deviceModel =
    currentAuthenticators.find(
      auth => auth.authenticatorId === currentAssuringAuthenticatorId,
    )?.deviceModel || '';

  deviceModel = deviceModel === 'unspecified' ? '' : deviceModel;

  const translatedDeviceModel =
    deviceModel &&
    i18n.t(`data.${deviceModel}`, {
      ns: 'deviceModel',
      defaultValue: deviceModel,
    });

  const removeModelFromAndroidRegex =
    /^([a-zA-Z0-9_\-() ]+)\([a-zA-Z0-9\-_ ]+\)$/gm;
  deviceModel = deviceModel.replace(removeModelFromAndroidRegex, '$1');

  const deviceModelToDisplay = translatedDeviceModel || deviceModel;

  const assuranceId = useSelector(
    (state: RootState) => state.assuranceWorkflow.assuranceId,
  );

  return (
    <AssuranceWizardLayout>
      <div className={classes.outerBox}>
        <div className={classes.authorized}>
          {t('assurance.verification.impr-id.supervisor-label')}
        </div>

        <div className={classes.headerSpacing}>
          <Row>
            <div className={classes.header}>
              {t('assurance.verification.impr-id.header', {
                deviceName: deviceModelToDisplay,
              })}
            </div>
          </Row>
          <Row>
            <div className={classes.instructions}>
              {t('assurance.verification.impr-id.instructions')}
            </div>
          </Row>
          <Row>
            <div className={classes.otpInputContainer}>
              <Form
                form={form}
                layout="vertical"
                name="register"
                onChange={handleChange}
              >
                <TokenInput width="200px" tokenInputAutofocus />
                <Row className={classes.verifyIIDRow}>
                  <Button
                    label={t('actions.continue-to-authenticate')}
                    variant={ButtonVariant.PRIMARY}
                    block={true}
                    htmlType="submit"
                    onClick={() => verify()}
                    data-testid="totp-submit-button"
                    disabled={isSaveDisabled}
                  />
                </Row>
                <Row
                  data-testid="cancel-row"
                  className={classes.verifyIIDCancelRow}
                >
                  <Button
                    type="text"
                    variant={ButtonVariant.TEXT}
                    label={t('actions.later')}
                    onClick={() => {
                      assuranceId &&
                        dispatch(
                          cancelSupervisedAssuranceAction.request({
                            assuranceId,
                          }),
                        );
                    }}
                  />
                </Row>
              </Form>
            </div>
          </Row>
        </div>
      </div>
    </AssuranceWizardLayout>
  );
};

export default VerifyImprId;
