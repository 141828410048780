// Copyright 2023, Imprivata, Inc.  All rights reserved.

import React from 'react';
import ChooseAuths from './ChooseAuths';
import { Col, Row } from 'antd';
import classes from './styles.module.less';
import { useTranslation } from 'react-i18next';
import { Authenticator, FactorTypeEnum } from '../../models/models';
import AssuranceWizardLayout from '../../components/layout/AssuranceWizardLayout';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import { redirectWithQuery } from '../../utils/routingHelpers';
import { AUTHENTICATORS_ROUTE } from '../../routers/route-names';
import { useStartGlobalUiIdleTimeout } from '../../utils/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import getConfig from '../../appConfigUtils';
interface Props {
  header: string;
  subHeader: string;
  disableCancel: boolean;
}
const EnrollAuthenticator: React.FC<Props> = ({
  header,
  subHeader,
  disableCancel,
}) => {
  const { t } = useTranslation();

  const { PIN_DISABLED } = getConfig();

  const { startTimer } = useStartGlobalUiIdleTimeout();
  startTimer();

  const currentAuthenticators = useSelector<RootState, Authenticator[]>(
    state =>
      state.authenticatorManagement.listAuthenticators.authenticators || [],
  );

  const hasEnrolledImprPIN = currentAuthenticators.some(
    a => a.factorType === FactorTypeEnum.IMPRIVATA_PIN,
  );

  const factorDetails = [
    { factor: FactorTypeEnum.IMPRIVATA_ID, enabled: true },
    ...(PIN_DISABLED
      ? []
      : [
          {
            factor: FactorTypeEnum.IMPRIVATA_PIN,
            enabled: !hasEnrolledImprPIN,
          },
        ]),
  ];

  return (
    <AssuranceWizardLayout>
      <div>
        <Row className={classes.noneHeader} align="middle">
          <Col span={17}>
            <div data-testid="new-here" className={classes.header}>
              {header || t('authenticators.add-header')}
            </div>
          </Col>
        </Row>
        <Row className={classes.noneMsg}>
          <div className={classes.subHeader}>
            {subHeader || t('authenticators.add-subheader')}
          </div>
        </Row>
        <ChooseAuths factorDetails={factorDetails} />
        {!disableCancel && (
          <Row className={classes.enrollCancel} justify="center">
            <Button
              type="text"
              variant={ButtonVariant.SECONDARY}
              label="Cancel"
              data-testid="cancel-button"
              onClick={() => redirectWithQuery(AUTHENTICATORS_ROUTE)}
            />
          </Row>
        )}
      </div>
    </AssuranceWizardLayout>
  );
};

export default EnrollAuthenticator;
