// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import clsx from 'clsx';
import { Col, Layout, Row } from 'antd';
import ImprivataLogo from '../ImprivataLogo';

const Footer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...rest
}) => {
  return (
    <Layout.Header className={clsx(className)} {...rest}>
      <Layout>
        <Row justify="space-between" align="middle">
          <Col>
            <ImprivataLogo width={150} />
          </Col>
        </Row>
      </Layout>
    </Layout.Header>
  );
};

export default Footer;
