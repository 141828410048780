// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { i18n } from './config';

export const antLocales: { [key in string]: typeof enUS } = {
  en: enUS,
};

export const LocaleProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Suspense fallback={<div>...Loading</div>}>
      <I18nextProvider i18n={i18n} defaultNS={'translation'}>
        <ConfigProvider locale={antLocales[i18n.language || 'en']}>
          {children}
        </ConfigProvider>
      </I18nextProvider>
    </Suspense>
  );
};
