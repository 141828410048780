// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  QueryParamKey,
  USER_ACTIVITIES_EVENTS,
} from '../containers/login/store/constants';
import { useIdleTimer } from 'react-idle-timer';
import { LOGOUT_ROUTE } from '../routers/route-names';
import { redirectWithQuery } from './routingHelpers';
import { useDispatch } from 'react-redux';
import { setSessionErrorCodeAction } from '../containers/login/store/actions';
import { SESSION_EXPIRED } from '../error-handler/constants';

export const useQueryParams = (): URLSearchParams => {
  const location = useLocation();
  return useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
};

export const useStartWindowCloseIdleTimeout = () => {
  const params = useQueryParams();
  const timeout = params.get(QueryParamKey.WINDOW_CLOSE_TIMEOUT) || '';
  const idleTimeout = 1000 * parseInt(timeout);

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    events: USER_ACTIVITIES_EVENTS,
    onIdle: () => {
      window.close();
    },
    debounce: 200,
    stopOnIdle: true,
    startManually: true,
  });

  // to start timer only if query provided
  const startTimer = () => {
    if (timeout) {
      idleTimer.activate();
    }
  };
  return { startTimer };
};

export const useStartGlobalUiIdleTimeout = () => {
  const dispatch = useDispatch();
  const idleTimer = useIdleTimer({
    timeout: 5 * 60 * 1000,
    events: USER_ACTIVITIES_EVENTS,
    onIdle: () => {
      dispatch(setSessionErrorCodeAction(SESSION_EXPIRED));
      redirectWithQuery(LOGOUT_ROUTE);
    },
    name: 'global-ui-timeout',
    stopOnIdle: true,
    startManually: true,
  });

  const startTimer = () => idleTimer.activate();
  return { startTimer };
};
