import { combineEpics, Epic } from 'redux-observable';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { EMPTY, from, fromEvent, merge, of } from 'rxjs';
import { RootAction } from '../../../store/rootAction';
import {
  assuranceIdErrorAction,
  cancelSupervisedAssuranceAction,
  cancelSupervisedAssurancWithoutIdAction,
  goToNextStep,
  pageRefreshOrCloseBeforeSupervisorAssuranceCompletion,
  setAssuranceIdAction,
  setAuthenticatorIdAction,
  supervisedAssuranceCompleteAction,
  supervisedAssuranceStartAction,
  verifyAuthenticatorTotpAction,
} from './actions';
import {
  cancelSupervisedAssurance,
  completeSupervisedAssurance,
  startSupervisedAssurance,
  verifyAuthenticator,
} from '../../../api/assuranceService';
import { ApiError, isErrorResponse } from '../../../api/types';
import { RootState } from '../../../store/rootReducer';
import { FactorTypeEnum } from '../../../models/models';
import { Banner } from '@imprivata-cloud/components';
import { cancelSupervisorAuthenticationAction } from '../../login/store/actions';
import { getErrorMessageCode } from '../../../i18n/utils';
import { ContextNames } from '../../../i18n/resources';
import { endWorkflowAction } from '../../workflow/store/actions';
import { AUTHENTICATORS_ROUTE } from '../../../routers/route-names';
import { redirectWithQuery } from '../../../utils/routingHelpers';
import { AuthenticationCancelReasonCode } from './constants';
import { StorageKeys } from '../../login/store/constants';
import { deleteHeader } from '../../../api/api';
import { Headers } from '../../../api/constants';
import { addErrorHandlerAction } from '../../../error-handler/store/action';
import { ERROR_CODES } from '../../../error-handler/constants';
import { i18n } from 'i18next';
import { getAuthnCodingContext } from '../../login/util';
import { SpanNames, tracer } from '../../../tracing';

const supervisedAssuranceCancelDueToPageCloseEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(pageRefreshOrCloseBeforeSupervisorAssuranceCompletion)),
    switchMap(() => {
      if (localStorage.getItem(StorageKeys.IS_SUPERVISOR_AUTHENTICATING)) {
        localStorage.removeItem(StorageKeys.IS_SUPERVISOR_AUTHENTICATING);
        return from([
          cancelSupervisorAuthenticationAction.request({
            reasonCode: AuthenticationCancelReasonCode.USER_CANCEL,
          }),
          cancelSupervisedAssurancWithoutIdAction(),
        ]);
      }
      return from([cancelSupervisedAssurancWithoutIdAction()]);
    }),
  );

const supervisedAssuranceStartEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(supervisedAssuranceStartAction.request)),
    switchMap(({ payload }) =>
      from(startSupervisedAssurance(payload)).pipe(
        tap(() => tracer.startSpan(SpanNames.AUTHENTICATOR_ASSURANCE_START)),
        switchMap(res => {
          if (isErrorResponse(res)) {
            // should not come here, this is just a type guard to satisfy typescript
            throw res;
          }

          const windowCloseObservable = fromEvent(window, 'beforeunload').pipe(
            map(_event =>
              pageRefreshOrCloseBeforeSupervisorAssuranceCompletion(),
            ),
            takeUntil(
              action$.pipe(
                filter(isActionOf(supervisedAssuranceCompleteAction.request)),
              ),
            ),
          );

          tracer.endSpan(SpanNames.AUTHENTICATOR_ASSURANCE_START, res);

          return merge(
            windowCloseObservable,
            from([setAssuranceIdAction(res.assuranceId)]),
          );
        }),
        catchError((error: ApiError) => {
          tracer.endSpan(SpanNames.AUTHENTICATOR_ASSURANCE_START, error);
          redirectWithQuery(AUTHENTICATORS_ROUTE);
          return from([
            supervisedAssuranceStartAction.failure(error),
            setAssuranceIdAction(null),
            addErrorHandlerAction({
              errorCode: getErrorMessageCode(
                ContextNames.ASSURANCE,
                error.code,
              ),
            }),
          ]);
        }),
      ),
    ),
  );

const verifyAuthenticatorTotpEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(verifyAuthenticatorTotpAction.request)),
    switchMap(({ payload: totp }) => {
      const codingContext = getAuthnCodingContext();
      if (
        state$.value.assuranceWorkflow.assuranceId === null ||
        !codingContext
      ) {
        redirectWithQuery(AUTHENTICATORS_ROUTE);
        return from([verifyAuthenticatorTotpAction.cancel()]);
      }
      if (state$.value.assuranceWorkflow.authenticatorId === null) {
        redirectWithQuery(AUTHENTICATORS_ROUTE);
        return of(assuranceIdErrorAction()); // TODO new error
      }

      return from(
        verifyAuthenticator({
          assuranceId: state$.value.assuranceWorkflow.assuranceId,
          authenticatorId: state$.value.assuranceWorkflow.authenticatorId,
          factorType: FactorTypeEnum.IMPRIVATA_ID,
          factorData: codingContext.encryptJson({
            version: '1.0',
            totp: totp,
          }),
        }),
      ).pipe(
        tap(() =>
          tracer.startSpan(SpanNames.AUTHENTICATOR_ASSURANCE_VERIFY_IMPRID),
        ),
        switchMap(res => {
          tracer.endSpan(SpanNames.AUTHENTICATOR_ASSURANCE_VERIFY_IMPRID);
          return of(goToNextStep());
        }),
        catchError((error: ApiError) => {
          tracer.endSpan(
            SpanNames.AUTHENTICATOR_ASSURANCE_VERIFY_IMPRID,
            error,
          );
          const errorCode = getErrorMessageCode(
            ContextNames.ASSURANCE,
            error.code,
          );
          if (Object.values(ERROR_CODES).includes(error.code as ERROR_CODES)) {
            redirectWithQuery(AUTHENTICATORS_ROUTE);
          }
          return of(
            addErrorHandlerAction({
              errorCode,
            }),
          );
        }),
      );
    }),
  );

const supervisedAssuranceCompleteEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  { i18n: i18n }
> = (action$, _, { i18n }) =>
  action$.pipe(
    filter(isActionOf(supervisedAssuranceCompleteAction.request)),
    switchMap(({ payload }) => {
      return from(completeSupervisedAssurance(payload)).pipe(
        switchMap(res => {
          localStorage.removeItem(StorageKeys.IS_SUPERVISOR_AUTHENTICATING);
          Banner({
            type: 'success',
            message: i18n.t('assurance.supervised.success-banner'),
            duration: 5,
            datatestid: 'assurance-completion-success',
          });
          return from([
            setAssuranceIdAction(null),
            setAuthenticatorIdAction(null),
            goToNextStep(),
            supervisedAssuranceCompleteAction.success(),
          ]);
        }),
        catchError((error: ApiError) => {
          const errorCode = getErrorMessageCode(
            ContextNames.SUPERVISOR,
            error.code,
          );
          if (
            errorCode === 'assurance.error.user-cannot-be-supervisor' &&
            localStorage.getItem(StorageKeys.IS_SUPERVISOR_AUTHENTICATING)
          ) {
            //do not cancel Authentication in this case
            localStorage.removeItem(StorageKeys.IS_SUPERVISOR_AUTHENTICATING);
          }
          redirectWithQuery(AUTHENTICATORS_ROUTE);
          return of(
            supervisedAssuranceCompleteAction.failure(error),
            addErrorHandlerAction({
              errorCode,
            }),
          );
        }),
      );
    }),
  );

const supervisorAuthenticationWorkflowEndEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(
      // @TODO need to handle B-24: https://intranet.imprivata.com/display/OneSign/Authenticator+Assurance+Flows
      // i.e. when the session expires in the authenticate supervisor step.
      // C:B-22 also.
      isActionOf([
        supervisedAssuranceStartAction.failure,

        supervisedAssuranceCompleteAction.success,
        supervisedAssuranceCompleteAction.failure,

        cancelSupervisedAssuranceAction.success,
        cancelSupervisedAssuranceAction.failure,

        cancelSupervisorAuthenticationAction.success,
        cancelSupervisorAuthenticationAction.failure,
      ]),
    ),
    switchMap(() => {
      deleteHeader(Headers.ImprInAppSession);
      return of(endWorkflowAction());
    }),
  );

const cancelSupervisedAssuranceWorkflowEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf([cancelSupervisedAssuranceAction.request])),
    exhaustMap(({ payload }) => {
      return from(cancelSupervisedAssurance(payload)).pipe(
        switchMap(_res => {
          return of(cancelSupervisedAssuranceAction.success());
        }),
        catchError((error: ApiError) => {
          return of(
            cancelSupervisedAssuranceAction.failure(error),
            addErrorHandlerAction({ errorCode: error.code }),
          );
        }),
        takeUntil(
          action$.pipe(
            filter(isActionOf(cancelSupervisedAssuranceAction.cancel)),
          ),
        ),
      );
    }),
  );

/** Used when navigates to base url with back button */
const cancelSupervisedAssuranceWithoutIdEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([cancelSupervisedAssurancWithoutIdAction])),
    switchMap(() => {
      const assuranceId = state$.value.assuranceWorkflow.assuranceId;
      const sessionErrorCode = state$.value.login.sessionErrorCode;
      if (assuranceId === null || sessionErrorCode) {
        // TODO send trace log because user tried to cancel but the assuranceId was missing
        return from(EMPTY);
      }

      return of(cancelSupervisedAssuranceAction.request({ assuranceId }));
    }),
  );

export const supervisedAssuranceEpics: Epic = combineEpics(
  supervisedAssuranceStartEpic,
  verifyAuthenticatorTotpEpic,
  supervisedAssuranceCompleteEpic,
  supervisorAuthenticationWorkflowEndEpic,
  cancelSupervisedAssuranceWorkflowEpic,
  cancelSupervisedAssuranceWithoutIdEpic,
  supervisedAssuranceCancelDueToPageCloseEpic,
);
