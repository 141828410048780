import { PKCECodes } from './api/types';
import { StorageKeys as LoginKeys } from './containers/login/store/constants';

type StorageKey = LoginKeys;

export function saveToStorage(key: StorageKey, value: string): void {
  localStorage.setItem(key, value);
}

export function getFromStorage(key: StorageKey): string | null {
  return localStorage.getItem(key);
}

export function removeFromStorage(key: StorageKey): void {
  localStorage.removeItem(key);
}

function dec2hex(dec: number) {
  return ('0' + dec.toString(16)).substr(-2);
}

export function generateCodeVerifier(): string {
  const array = new Uint32Array(32);
  crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}

// GENERATING CODE CHALLENGE FROM VERIFIER:
export function sha256(plain: string): Promise<ArrayBuffer> {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return crypto.subtle.digest('SHA-256', data);
}

export function base64URLencode(a: ArrayBuffer): string {
  let str = '';
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export async function generateCodeChallengeFromVerifier(
  verifier: string,
): Promise<string> {
  const hashed = await sha256(verifier);
  return base64URLencode(hashed);
}

export async function generatePKCECodes(): Promise<PKCECodes> {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);

  return { codeVerifier, codeChallenge };
}
