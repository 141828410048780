// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.less';
import { Authenticator, FactorTypeEnum } from '../../models/models';
import { useSelector } from 'react-redux';
import { userDisplayNameSelector } from '../login/store/selectors';
import { Col, Row } from 'antd';
import AuthenticatorListItem from '../../components/assure/AuthenticatorListItem';
import { SINGLE_INSTANCE_AUTHENTICATORS } from './store/constants';
import { getFactorTypeIcon } from '../../utils/IconUtil';
import { TFunction } from 'i18next';

export const getFactorTypeLabel = (
  factorType: FactorTypeEnum,
  t: TFunction<'translation', undefined>,
) => {
  switch (factorType) {
    case FactorTypeEnum.PROX_CARD:
      return t('authenticators.factorTypeLabels.PROX_CARD');
    case FactorTypeEnum.IMPRIVATA_ID:
      return t('authenticators.factorTypeLabels.IMPRIVATA_ID');
    case FactorTypeEnum.IMPRIVATA_PIN:
      return t('authenticators.factorTypeLabels.IMPRIVATA_PIN');
    default:
      console.error('Unsupported factor type ' + factorType);
      return '';
  }
};

export const isSingleInstanceAuth = (factorType: FactorTypeEnum) => {
  return SINGLE_INSTANCE_AUTHENTICATORS.includes(factorType);
};

interface Props {
  authenticators: Authenticator[] | null;
}

const AuthenticatorList: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const username = useSelector(userDisplayNameSelector);
  const { authenticators } = props;

  const newHere = (
    <>
      <div className={classes.noneMsg}>
        {t('authenticators.none', { username })}
      </div>
      <div>{t('authenticators.none-instructions', { username })}</div>
    </>
  );

  const content =
    authenticators && authenticators.length > 0
      ? Object.values(FactorTypeEnum).map(factorType => {
          const innerContent = authenticators
            .filter(a => a.factorType === factorType)
            .map((auth: Authenticator) => (
              <AuthenticatorListItem key={auth.authenticatorId} auth={auth} />
            ));
          return (
            !!innerContent.length && (
              <div className={classes.authTypeHeader} key={factorType}>
                {!isSingleInstanceAuth(factorType) && (
                  <Row className={classes.authenticatorHeader}>
                    <Col>
                      {getFactorTypeIcon(factorType, '#333333', {
                        height: '24px',
                        width: '24px',
                      })}
                    </Col>
                    <Col>
                      <span className={classes.authenticatorTypeLabel}>
                        {getFactorTypeLabel(factorType, t)}
                      </span>
                    </Col>
                  </Row>
                )}
                {innerContent}
              </div>
            )
          );
        })
      : newHere;

  return <div className={classes.listArea}>{content}</div>;
};

export default AuthenticatorList;
