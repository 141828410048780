import { useSelector } from 'react-redux';
import { loginSelector } from './selectors';

export const useLoginHook = (): {
  sessionErrorCode?: string;
  userId?: string;
  sessionErrorMessageKey?: string;
  logoURL?: string;
  passwordLabel?: string;
  userNameLabel?: string;
  username?: string;
  isLoading: boolean;
} => {
  const {
    user,
    logoURL,
    userNameLabel,
    passwordLabel,
    sessionErrorCode,
    username,
    requestingClientSession,
  } = useSelector(loginSelector);

  return {
    isLoading: requestingClientSession,
    logoURL,
    userNameLabel,
    passwordLabel,
    sessionErrorCode,
    userId: user?.userId,
    username,
  };
};
