import { QueryParamKey, StorageKeys } from './constants';
import { getFromStorage } from '../../../utils';
import { setHeader } from '../../../api/api';
import { Headers } from '../../../api/constants';
import { createReducer } from 'typesafe-actions';
import { RootAction } from '../../../store/rootAction';
import {
  clearErrorsAction,
  logoutAction,
  setSessionErrorCodeAction,
  startClientSessionAction,
} from './actions';

export interface LoginState {
  sessionErrorCode?: string;
  requestingClientSession: boolean;

  tenantId?: string;
  username: string;
  user?: User;

  logoURL?: string;
  userNameLabel?: string;
  passwordLabel?: string;
}

// TODO move to model
export type User = {
  userId: string;
  displayName: string;
  email: string;
  mobilePhone: string;
  firstName?: string;
  lastName?: string;
};

export const initialState: LoginState = {
  requestingClientSession: false,
  tenantId: undefined,
  username: '',
  user: undefined,
};

export function rehydrate(): LoginState {
  const storedUser = getFromStorage(StorageKeys.USER) || undefined;
  const currentSearchParams = new URLSearchParams(window.location.search);
  const tenantId = currentSearchParams.get(QueryParamKey.TENANT_ID) || '';

  if (tenantId) {
    setHeader(Headers.ImprTenantId, tenantId);
  }

  return {
    ...initialState,
    tenantId,
    user: storedUser && JSON.parse(storedUser),
  };
}

export const loginReducer = createReducer<LoginState, RootAction>(initialState)
  .handleAction(startClientSessionAction.request, (state, { payload }) => {
    const tenantId = payload.tenantId || state.tenantId;
    return {
      ...state,
      tenantId,
      user: undefined,
      requestingClientSession: true,
    };
  })
  .handleAction(startClientSessionAction.success, (state, { payload }) => {
    return {
      ...state,
      user: payload.user,
      requestingClientSession: false,
    };
  })
  .handleAction(
    startClientSessionAction.failure,
    // TODO add startClientSessionError state
    (state, { payload: startClientSessionError }) => {
      return {
        ...state,
        user: undefined,
        requestingClientSession: false,
      };
    },
  )
  .handleAction(logoutAction.success, state => {
    const { tenantId } = state;
    // preserves tenantId so user can be redirected
    // back to login page without typing tenantId again
    return {
      ...initialState,
      tenantId,
    };
  })
  .handleAction(setSessionErrorCodeAction, (state, { payload }) => {
    const { tenantId } = state;
    // preserves tenantId so user can be redirected
    // back to login page without typing tenantId again
    return {
      ...initialState,
      user: undefined,
      tenantId,
      sessionErrorCode: payload,
    };
  })
  .handleAction(clearErrorsAction, state => ({
    ...state,
    sessionErrorCode: undefined,
  }));
