import { createAction, createAsyncAction } from 'typesafe-actions';
import { SupervisedAssuranceCompleteRequest } from '../../../api/assuranceService';
import { ApiError, PKCECodes } from '../../../api/types';

export const startAssureAuthenticatorAction = createAction(
  'assure/SHOW_START_ASSURANCE_SCREEN',
)();

export const idProofingCompletedAction = createAction(
  'assure/ID_PROOFING_COMPLETED',
)();

export const individualAssuranceCompletedAction = createAction(
  'assure/INDIVIDUAL_ASSURANCE_COMPLETED',
)();

export const supervisedAssuranceCompleteAction = createAsyncAction(
  'assure/SUPERVISED_ASSURANCE_COMPLETE_REQUEST',
  'assure/SUPERVISED_ASSURANCE_COMPLETE_SUCCESS',
  'assure/SUPERVISED_ASSURANCE_COMPLETE_FAILURE',
  'assure/SUPERVISED_ASSURANCE_COMPLETE_CANCEL',
)<SupervisedAssuranceCompleteRequest, void, ApiError, void>();

export const idProofingSessionActions = createAsyncAction(
  'assure/START_ID_PROOFING_SESSION_REQUEST',
  'assure/START_ID_PROOFING_SESSION_SUCCESS',
  'assure/START_ID_PROOFING_SESSION_FAILURE',
  'assure/START_ID_PROOFING_SESSION_CANCEL',
)<void, string, ApiError, void>();

/** @description Use it only in the epic that orchestrates the assurance workflow */
export const goToNextStep = createAction('assure/GO_TO_NEXT_STEP')();

export const wrongOtpAction = createAction('assure/WRONG_OTP')();

export const setCurrentWorkflow = createAction('assure/SET_CURRENT_WORKFLOW')<
  string | null
>();

export const assuranceWorkflowSequenceError = createAction(
  'assure/ASSURANCE_WORKFLOW_SEQUENCE_ERROR',
)();

export const verifyEmailAction = createAction('assure/VERIFY_BY_EMAIL')();
export const verifyPhoneAction = createAction('assure/VERIFY_PHONE')();
export const verifyAuthenticatorAction = createAction(
  'assure/VERIFY_AUTHENTICATOR',
)();
export const verifyAuthenticatorContinueAction = createAction(
  'assure/VERIFY_AUTHENTICATOR_CONTINUE',
)();

export const assureSupervisorAction = createAction(
  'assure/ASSURE_SUPERVISOR',
)();

export const startVerifyIdentityAction = createAction(
  'assure/START_VERIFY_IDENTITY',
)();

export const setPkseCodesAction = createAction(
  'assure/SET_PKCE_CODE_GENERATION',
)<PKCECodes | null>();

export const individualAssuranceStartAction = createAsyncAction(
  'assure/START_INDIVIDUAL_ASSURANCE_REQUEST',
  'assure/START_INDIVIDUAL_ASSURANCE_SUCCESS',
  'assure/START_INDIVIDUAL_ASSURANCE_FAILURE',
  'assure/START_INDIVIDUAL_ASSURANCE_CANCEL',
)<void, string, ApiError, void>();

export const supervisedAssuranceStartAction = createAsyncAction(
  'assure/START_SUPERVISED_ASSURANCE_REQUEST',
  'assure/START_SUPERVISED_ASSURANCE_SUCCESS',
  'assure/START_SUPERVISED_ASSURANCE_FAILURE',
  'assure/START_SUPERVISED_ASSURANCE_CANCEL',
)<string, string, ApiError, void>();

export const cancelSupervisedAssuranceAction = createAsyncAction(
  'assure/CANCEL_SUPERVISED_ASSURANCE_REQUEST',
  'assure/CANCEL_SUPERVISED_ASSURANCE_SUCCESS',
  'assure/CANCEL_SUPERVISED_ASSURANCE_FAILURE',
  'assure/CANCEL_SUPERVISED_ASSURANCE_CANCEL',
)<{ assuranceId: string }, void, ApiError, void>();

export const cancelSupervisedAssurancWithoutIdAction = createAction(
  'assure/CANCEL_SUPERVISED_ASSURANCE_WITHOUT_ID',
)();

export const sendOtpByEmailAction = createAsyncAction(
  'assure/SEND_OTP_BY_EMAIL_REQUEST',
  'assure/SEND_OTP_BY_EMAIL_SUCCESS',
  'assure/SEND_OTP_BY_EMAIL_FAILURE',
  'assure/SEND_OTP_BY_EMAIL_CANCEL',
)<void, string, ApiError, void>();

export const verifyEmailOtpAction = createAsyncAction(
  'assure/VERIFY_EMAIL_OTP_REQUEST',
  'assure/VERIFY_EMAIL_OTP_SUCCESS',
  'assure/VERIFY_EMAIL_OTP_FAILURE',
  'assure/VERIFY_EMAIL_OTP_CANCEL',
)<string, string, ApiError, void>();

export const sendOtpBySmsAction = createAsyncAction(
  'assure/SEND_OTP_BY_SMS_REQUEST',
  'assure/SEND_OTP_BY_SMS_SUCCESS',
  'assure/SEND_OTP_BY_SMS_FAILURE',
  'assure/SEND_OTP_BY_SMS_CANCEL',
)<void, string, ApiError, void>();

export const verifyPhoneOtpAction = createAsyncAction(
  'assure/VERIFY_PHONE_OTP_REQUEST',
  'assure/VERIFY_PHONE_OTP_SUCCESS',
  'assure/VERIFY_PHONE_OTP_FAILURE',
  'assure/VERIFY_PHONE_OTP_CANCEL',
)<string, string, ApiError, void>();

export const verifyIdentityAction = createAsyncAction(
  'assure/VERIFY_IDENTITY_REQUEST',
  'assure/VERIFY_IDENTITY_SUCCESS',
  'assure/VERIFY_IDENTITY_FAILURE',
  'assure/VERIFY_IDENTITY_CANCEL',
)<string, void, ApiError, void>();

export const verifyAuthenticatorTotpAction = createAsyncAction(
  'assure/VERIFY_AUTHENTICATOR_REQUEST',
  'assure/VERIFY_AUTHENTICATOR_FAILURE',
  'assure/VERIFY_AUTHENTICATOR_SUCCESS',
  'assure/VERIFY_AUTHENTICATOR_CANCEL',
)<string, string, ApiError, void>();

export const assuranceIdErrorAction = createAction(
  'assure/ASSURANCE_ID_NOT_FOUND',
)();

export const setAssuranceIdAction = createAction('assure/SET_ASSURANCE_ID')<
  string | null
>();

export const setAuthenticatorIdAction = createAction(
  'assure/SET_AUTHENTICATOR_ID',
)<string | null>();

export const setOtpIdAction = createAction('assure/SET_OTP_ID')<
  string | null
>();

export const pageRefreshOrCloseBeforeSupervisorAssuranceCompletion =
  createAction('assure/CANCEL_ON_PAGE_CLOSE')<void, void>();
