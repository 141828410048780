import { Button, ButtonVariant } from '@imprivata-cloud/components';
import { Row } from 'antd';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AssuranceWizardLayout from '../../components/layout/AssuranceWizardLayout';
import {
  cancelSupervisedAssuranceAction,
  goToNextStep,
  setCurrentWorkflow,
} from './store/actions';
import classes from './styles.module.less';
import assureSrc from '../../assets/assure_for_epcs.png';
import { RootState } from '../../store/rootReducer';
import { redirectWithQuery } from '../../utils/routingHelpers';
import { AUTHENTICATORS_ROUTE } from '../../routers/route-names';

const AssureAuthenticatorStart: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assuranceId = useSelector(
    (state: RootState) => state.assuranceWorkflow.assuranceId,
  );

  const assuranceCancelOnClickHandle = () => {
    if (assuranceId) {
      dispatch(cancelSupervisedAssuranceAction.request({ assuranceId }));
    } else {
      setCurrentWorkflow(null);
      redirectWithQuery(AUTHENTICATORS_ROUTE);
    }
  };

  return (
    <AssuranceWizardLayout>
      <div className={classes.topSpacing}>
        <div className={classes.sidebarGrid}>
          <div>
            <img
              className={classes.supervisorImage}
              alt="Assure for EPCS"
              src={assureSrc}
              data-testid="assure-icon"
            />
          </div>
          <div className={classes.centeredArea}>
            <Row>
              <div className={classes.header}>
                {t('assurance.supervised.header')}
              </div>
            </Row>
            <Row className={classes.assuranceCompleteHeader}>
              <p>
                <Trans
                  t={t}
                  i18nKey={`assurance.supervised.start-instruction`}
                />
              </p>
            </Row>
            <Row className={classes.instructionSection}>
              <ul data-testid="instructions-list">
                <li>{t('assurance.supervised.instruction-1')}</li>
                <li>{t('assurance.supervised.instruction-2')}</li>
                <li>{t('assurance.supervised.instruction-3')}</li>
              </ul>
            </Row>
            <Row className={classes.shortPaddedRow}>
              <Button
                variant={ButtonVariant.PRIMARY}
                label="Continue"
                data-testid="assurance-continue-button"
                onClick={() => dispatch(goToNextStep())}
                disabled={!assuranceId}
                autoFocus={!!assuranceId}
              />
            </Row>
            <Row className={classes.shortCancelRow}>
              <Button
                type="text"
                variant={ButtonVariant.TEXT}
                label={t('actions.later')}
                onClick={assuranceCancelOnClickHandle}
              />
            </Row>
          </div>
        </div>
      </div>
    </AssuranceWizardLayout>
  );
};

export default AssureAuthenticatorStart;
