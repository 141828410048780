import { createReducer } from 'typesafe-actions';
import { PKCECodes } from '../../../api/types';
import { RootAction } from '../../../store/rootAction';
import { cancelSupervisorAuthenticationAction } from '../../login/store/actions';
import {
  cancelSupervisedAssuranceAction,
  goToNextStep,
  setAssuranceIdAction,
  setAuthenticatorIdAction,
  setCurrentWorkflow,
  setOtpIdAction,
  setPkseCodesAction,
  supervisedAssuranceCompleteAction,
} from './actions';

const assuranceWorkflowSequence: { [K in string]: string[] } = {
  individual: [
    'start-assurance',
    'verify-email',
    'verify-phone',
    'id-proofing',
    'assurance-complete',
  ],
  supervised: [
    'start-assurance',
    'verify-identity',
    'verify-authenticator',
    'supervisor-authenticate',
    'assurance-complete',
  ],
  'workflow-2': ['start-assurance', 'id-proofing', 'assurance-complete'],
};

export interface AssuranceWorkflowState {
  currentWorkflow: string | null;
  currentStep: string | null;
  authenticatorId: string | null;
  assuranceId: string | null;
  otpId: string | null;
  pkceCodes: PKCECodes | null;
}

export const initialState: AssuranceWorkflowState = {
  currentWorkflow: null,
  currentStep: null,
  authenticatorId: null,
  assuranceId: null,
  otpId: null,
  pkceCodes: null,
};

export const assuranceWorkflowReducer = createReducer<
  AssuranceWorkflowState,
  RootAction
>(initialState)
  .handleAction([setCurrentWorkflow], (state, { payload }) => {
    return {
      ...state,
      currentWorkflow: payload,
      currentStep: payload && assuranceWorkflowSequence[payload][0],
    };
  })
  .handleAction(goToNextStep, state => {
    if (state.currentWorkflow === null || state.currentStep === null)
      return state;

    const currentWorkflowSequence =
      assuranceWorkflowSequence[state.currentWorkflow];
    const nextStep =
      currentWorkflowSequence[
        currentWorkflowSequence.indexOf(state.currentStep) + 1
      ];
    return { ...state, currentStep: nextStep };
  })
  .handleAction(setAuthenticatorIdAction, (state, { payload }) => {
    return {
      ...state,
      authenticatorId: payload,
    };
  })
  .handleAction(setAssuranceIdAction, (state, { payload }) => {
    return {
      ...state,
      assuranceId: payload,
    };
  })
  .handleAction(setPkseCodesAction, (state, { payload }) => {
    return {
      ...state,
      pkceCodes: payload,
    };
  })
  .handleAction(setOtpIdAction, (state, { payload }) => {
    return {
      ...state,
      otpId: payload,
    };
  })
  .handleAction(
    supervisedAssuranceCompleteAction.success,
    (state): AssuranceWorkflowState => {
      return {
        ...state,
      };
    },
  )
  .handleAction(
    [
      supervisedAssuranceCompleteAction.failure,
      cancelSupervisedAssuranceAction.success,
      cancelSupervisedAssuranceAction.failure,
      cancelSupervisorAuthenticationAction.success,
    ],
    (): AssuranceWorkflowState => {
      return {
        ...initialState,
      };
    },
  );
