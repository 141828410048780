import {
  CipherV1,
  CodingContext,
  getRSAPublicKey,
} from '@imprivata-cloud/data-privacy-js';
import { StorageKeys } from './store/constants';
import getConfig from '../../appConfigUtils';

export function getAuthnCodingContext(): CodingContext | void {
  const urlParams = new URLSearchParams(window.location.search);
  const id = window.localStorage.getItem(StorageKeys.CODING_CTX_ID);
  const dataKey = window.localStorage.getItem(StorageKeys.CODING_CTX_DATAKEY);
  const encryptedDataKey = window.localStorage.getItem(
    StorageKeys.CODING_CTX_ENCRYPTED_DATAKEY,
  );
  if (id && dataKey && encryptedDataKey) {
    const cipherV1 = new CipherV1();
    const ccDataKey = new Uint8Array(JSON.parse(dataKey));
    return new CodingContext(
      Number(id),
      urlParams.has('cipherV2') ? 2 : 1,
      cipherV1,
      ccDataKey,
      new Uint8Array(JSON.parse(encryptedDataKey)),
    );
  }
}

export async function createNewCodingContext(): Promise<CodingContext> {
  const urlParams = new URLSearchParams(window.location.search);
  const { METADATA_URL } = getConfig();

  if (urlParams.has('cipherV2')) {
    const publicDev = await getRSAPublicKey(
      `${METADATA_URL}/data-privacy/public.json`,
    );
    const codingContextV2 = await CodingContext.createContextV2(
      publicDev.key,
      publicDev.version,
    );
    console.log('CipherV2.');
    return codingContextV2;
  }
  return CodingContext.createContextV1();
}
