import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AUTHENTICATORS_ROUTE } from '../../routers/route-names';
import { RootState } from '../../store/rootReducer';
import { cancelSupervisorAuthenticationAction } from '../login/store/actions';
import AssureAuthenticatorStart from './AssureAuthenticatorStart';
import {
  cancelSupervisedAssurancWithoutIdAction,
  cancelSupervisedAssuranceAction,
  setCurrentWorkflow,
} from './store/actions';
import SupervisorAuthenticate from './SupervisorAuthenticate';
import VerifyIdentity from './VerifyPhotoID';
import VerifyImprId from './VerifyImprId';
import {
  RedirectWithQuery,
  redirectWithQuery,
} from '../../utils/routingHelpers';
import {
  ASSURANCE_IDLE_TIMEOUT_MS,
  AuthenticationCancelReasonCode,
  USER_ACTIVITIES_EVENTS,
} from './store/constants';
import { StorageKeys } from '../login/store/constants';
import { useIdleTimer } from 'react-idle-timer';

const AssuranceRouter: React.FC = () => {
  const assuranceWorkflow = useSelector(
    (state: RootState) => state.assuranceWorkflow,
  );

  const { currentStep, assuranceId } = assuranceWorkflow;
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      /**
       * If UI timeout happens, the assurance idle timer below will redirect to home page
       * thus unmounting this component.
       * And supervisor authentication termination happens whenever this component unmounts.
       * So no extra action is required for this case.
       * @jira RA-1310
       */
      if (localStorage.getItem(StorageKeys.IS_SUPERVISOR_AUTHENTICATING)) {
        localStorage.removeItem(StorageKeys.IS_SUPERVISOR_AUTHENTICATING);
        dispatch(
          cancelSupervisorAuthenticationAction.request({
            reasonCode: AuthenticationCancelReasonCode.USER_CANCEL,
          }),
        );
      }

      dispatch(cancelSupervisedAssurancWithoutIdAction());
    };
  }, [dispatch]);

  useIdleTimer({
    timeout: ASSURANCE_IDLE_TIMEOUT_MS,
    events: USER_ACTIVITIES_EVENTS,
    onIdle: () => {
      if (assuranceId) {
        dispatch(
          cancelSupervisedAssuranceAction.request({
            assuranceId,
          }),
        );
      } else {
        setCurrentWorkflow(null);
        redirectWithQuery(AUTHENTICATORS_ROUTE);
      }
    },
    name: 'assurance-timeout',
  });

  switch (currentStep) {
    case 'start-assurance':
      return <AssureAuthenticatorStart />;
    case 'verify-identity':
      return <VerifyIdentity />;
    case 'verify-authenticator':
      return <VerifyImprId />;
    case 'supervisor-authenticate':
      return <SupervisorAuthenticate />;
    default:
      return (
        <RedirectWithQuery to={AUTHENTICATORS_ROUTE + window.location.search} />
      );
  }
};

export default AssuranceRouter;
