// Copyright 2022, Imprivata, Inc.  All rights reserved.

export interface Authenticator {
  authenticatorId: string;
  userId: string;
  shortName: string;
  lastUsedAt: string;
  deviceModel: string;
  factorType: FactorTypeEnum;
  assuranceType: AssuranceType;
}

export enum FactorTypeEnum {
  'FACIAL' = 'facial',
  'FIDO' = 'fido',
  'FINGERPRINT' = 'fingerprint',
  'IMPRIVATA_ID' = 'impr-id',
  'IMPRIVATA_PIN' = 'impr-pin',
  'PROX_CARD' = 'prox',
  'PWD' = 'pwd',
}

export enum AssuranceType {
  UNSPECIFIED = 'unspecified',
  IAL2_ASSURED = 'ial2-assured',
}
export interface DeviceData {
  phoneAttributes: string[]; // base64 encoded, details TBD
}

export interface SystemData {
  browserAttributes: string[]; // base64 encoded, details TBD
}
