// Copyright 2022, Imprivata, Inc.  All rights reserved.

import axios, { AxiosError, AxiosResponse } from 'axios';
import { client } from './client';
import { AppDispatch } from '../store/createStore';
import { ApiResponse, ErrorResponse, isErrorResponse } from './types';
import { setSessionErrorCodeAction } from '../containers/login/store/actions';
import { authz } from './endpoint-names';
import { SESSION_EXPIRED } from '../error-handler/constants';
import { createContextPropagationInterceptor } from '@imprivata-cloud/common';
import { tracer } from '../tracing';

export const errorHandlingInterceptor = (
  res: AxiosResponse<ApiResponse<unknown>>,
): AxiosResponse<ApiResponse<unknown>> => {
  if (axios.isAxiosError(res)) {
    throw res.response?.data.error;
  }
  if (isErrorResponse(res)) {
    throw res.error;
  }
  return res;
};

export const invalidSessionInterceptor = (dispatch: AppDispatch) => {
  return (res: AxiosError): AxiosError<ApiResponse<unknown>> => {
    const dataApiError = res.response?.data as ErrorResponse;
    if (res.response?.status === 401) {
      const invalidSessionLogoutCodes = [
        'session-expired',
        'invalid-session-id',
        'session-not-found',
      ];
      if (
        invalidSessionLogoutCodes.includes(dataApiError?.error.code) &&
        // Do not start logout if you are already calling logout
        !res?.request?.responseURL?.includes(authz.LOGOUT)
      ) {
        dispatch(setSessionErrorCodeAction(SESSION_EXPIRED));
      }
    } else if (res.response && res.response?.status > 500) {
      dataApiError.error.code = 'fatal';
    }

    return res;
  };
};

let requestInterceptors: number[] = [];
let responseInterceptors: number[] = [];

export const applyInterceptors = (dispatch: AppDispatch): void => {
  responseInterceptors.push(
    client.interceptors.response.use(
      undefined,
      invalidSessionInterceptor(dispatch),
    ),
    client.interceptors.request.use(
      createContextPropagationInterceptor(tracer),
    ),
    client.interceptors.response.use(errorHandlingInterceptor),
  );
};

export const ejectInterceptors = (): void => {
  requestInterceptors.forEach(interceptor =>
    client.interceptors.request.eject(interceptor),
  );

  responseInterceptors.forEach(interceptor =>
    client.interceptors.response.eject(interceptor),
  );

  requestInterceptors = [];
  responseInterceptors = [];
};
