// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams } from '../../../utils/hooks';
import { listAuthenticatorsActions } from './actions';
import { ErrorMessages, QueryParamKey } from './constants';
import { getAuthenticatorsState } from './selectors';
import { ListAuthenticatorsState } from './reducer';

export function useGetAuthenticators(
  refreshFlag: boolean,
): ListAuthenticatorsState {
  const dispatch = useDispatch();
  const listAuthState: ListAuthenticatorsState = useSelector(
    getAuthenticatorsState,
  );

  const params = useQueryParams();
  const tenantId = params.get(QueryParamKey.TENANT_ID);

  useEffect(() => {
    if (!tenantId) {
      dispatch(
        listAuthenticatorsActions.failure({
          code: ErrorMessages.TENANT_ID_NOT_PROVIDED,
        }),
      );
    } else {
      dispatch(listAuthenticatorsActions.request({ tenantId }));
    }

    return () => {
      dispatch(listAuthenticatorsActions.cancel());
    };
  }, [dispatch, tenantId, refreshFlag]);

  return listAuthState;
}
