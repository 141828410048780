import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginContainer from './LoginContainer';
import { LOGIN_ROUTE } from '../../routers/route-names';

import styles from './LoginLayout.module.less';

const LoginRoutes: React.FC = () => {
  return (
    <div className={styles.mainContainer} data-testid="login-layout">
      <Switch>
        <Route exact path={LOGIN_ROUTE} component={LoginContainer} />
      </Switch>
    </div>
  );
};

export default LoginRoutes;
