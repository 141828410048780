import { fetchWithKeepAlive } from './client';
import { authn } from './endpoint-names';
import { ApiResponse } from './types';
import { Headers } from './constants';
import { deleteHeader, setHeader } from './api';

export function cancelSupervisorAuthentication(
  payload: {
    reasonCode: string;
  },
  inAppSessionHeaderValue?: string,
): Promise<ApiResponse<void>> {
  inAppSessionHeaderValue &&
    setHeader(Headers.ImprInAppSession, inAppSessionHeaderValue);
  return fetchWithKeepAlive
    .client(authn.TERMINATE_SESSION, payload)
    .then(async r => {
      if (r.ok) {
        return Promise.resolve();
      } else {
        const error = await r.json();
        return Promise.reject(error.error);
      }
    })
    .finally(
      () => inAppSessionHeaderValue && deleteHeader(Headers.ImprInAppSession),
    );
}
