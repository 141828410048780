// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  ApiError,
  AuthenticatorsPayload,
  AuthenicatorEnrollResponse,
  AuthenticatorUnenrollRequest,
  AuthenicatorUnenrollResponse,
  AuthenticatorsRequest,
} from '../../../api/types';
import { Authenticator } from '../../../models/models';
import { AuthenticatorEnrollPayload } from './types';

export const listAuthenticatorsActions = createAsyncAction(
  'enroll/FETCH_AUTHENTICATORS_REQUEST',
  'enroll/FETCH_AUTHENTICATORS_SUCCESS',
  'enroll/FETCH_AUTHENTICATORS_FAILURE',
  'enroll/FIND_AUTHENTICATORS_CANCEL',
)<AuthenticatorsRequest, AuthenticatorsPayload, ApiError, string | void>();

export const enrollAuthenticatorActions = createAsyncAction(
  'enroll/ENROLL_AUTHENTICATOR_REQUEST',
  'enroll/ENROLL_AUTHENTICATOR_SUCCESS',
  'enroll/ENROLL_AUTHENTICATOR_FAILURE',
  'enroll/ENROLL_AUTHENTICATOR_CANCEL',
)<
  AuthenticatorEnrollPayload,
  AuthenicatorEnrollResponse,
  ApiError,
  string | void
>();

export const unenrollAuthenticatorActions = createAsyncAction(
  'enroll/UNENROLL_AUTHENTICATOR_REQUEST',
  'enroll/UNENROLL_AUTHENTICATOR_SUCCESS',
  'enroll/UNENROLL_AUTHENTICATOR_FAILURE',
  'enroll/UNENROLL_AUTHENTICATOR_CANCEL',
)<
  AuthenticatorUnenrollRequest,
  AuthenicatorUnenrollResponse,
  ApiError,
  string | void
>();

export const addAuthenticatorAction = createAction(
  'enroll/ADD_AUTHENTICATOR',
)<Authenticator>();
