// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { endWorkflowAction, startWorkflowAction } from './actions';
import { RootAction } from '../../../store/rootAction';

export interface WorkflowState {
  currentWorkflow: string | null;
}

export const initialState: WorkflowState = {
  currentWorkflow: null,
};

export const workflowReducer = combineReducers<WorkflowState>({
  currentWorkflow: createReducer<string | null, RootAction>(
    initialState.currentWorkflow,
  )
    .handleAction(startWorkflowAction, (state, { payload }) => payload)
    .handleAction(endWorkflowAction, () => null),
});
