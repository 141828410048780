import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SetTitle from '../../utils/DynamicTitleHelper';
import { Form, Row } from 'antd';
import { Button, ButtonVariant, InputBox } from '@imprivata-cloud/components';
import { useHistory, useLocation } from 'react-router-dom';
import { AUTHENTICATORS_ROUTE } from '../../routers/route-names';
import { AuthenticatorEnrollRequest } from '../../api/types';
import { FactorTypeEnum } from '../../models/models';
import { enrollAuthenticatorActions } from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedAuthenticatorState } from './store/selectors';
import AssuranceWizardLayout from '../../components/layout/AssuranceWizardLayout';
import iidSrc from '../../assets/USS_IID_164x246.png';
import classes from './styles.module.less';
import TokenInput from '../../components/assure/TokenInput';
import { startWorkflowAction } from '../workflow/store/actions';
import { v4 as uuidv4 } from 'uuid';
import { createNewCodingContext, getAuthnCodingContext } from '../login/util';
import { useStartGlobalUiIdleTimeout } from '../../utils/hooks';
import { CodingContext } from '@imprivata-cloud/data-privacy-js';

export const processSerialNumber = (val: string) => {
  let noSpaces = val.toUpperCase().replaceAll(' ', '');
  if (noSpaces.length === 8) {
    noSpaces = 'IMPR' + noSpaces;
  }
  return noSpaces;
};

const MAX_LENGTH_IMPRID_SL_NO_INPUT = 14;

const IIDEnrollment: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const requiredMsgKey = 'common.field.required';
  const { search } = useLocation();
  const selectedAuthenticator = useSelector(getSelectedAuthenticatorState);

  const { startTimer } = useStartGlobalUiIdleTimeout();
  startTimer();

  const handleChange = () => {
    setSaveDisabled(false);
    form
      .validateFields()
      .then(values => {
        if (
          !values.tokenValue ||
          values.tokenValue.length !== 6 ||
          !values.serialNumberValue
        ) {
          setSaveDisabled(true);
        }
      })
      .catch(errorInfo => {
        setSaveDisabled(true);
      });
  };

  const enroll = async () => {
    const authCodingContext = getAuthnCodingContext();
    const codingContext: CodingContext = authCodingContext
      ? authCodingContext
      : await createNewCodingContext();
    const factorType = selectedAuthenticator
      ? selectedAuthenticator.factorType
      : FactorTypeEnum.IMPRIVATA_ID;
    form.validateFields().then(values => {
      // TEMP: Placeholder
      const systemDataJson = {
        version: '1.0',
        osName: 'ToBeImplemented',
        osVersion: 'ToBeImplemented',
        computerName: 'ToBeImplemented',
      };
      const serialNumber = processSerialNumber(values.serialNumberValue);
      const enrollRequest: AuthenticatorEnrollRequest = {
        factorType,
        factorData: codingContext.encryptJson({
          version: '1.0',
          totp: values.tokenValue,
          serialNumber: serialNumber,
        }),
        systemData: codingContext.encryptJson(systemDataJson),
        deviceData: '', // placeholder
      };

      dispatch(startWorkflowAction(uuidv4()));
      dispatch(
        enrollAuthenticatorActions.request({
          authenticatorEnrollRequest: enrollRequest,
          iidSerialNumber: serialNumber,
          codingContext: codingContext.buildImprCodingCtxHeader(),
        }),
      );
      form.resetFields();
    });
  };

  const firstInstruction = t('authenticators.enroll.instructions.first');
  const secondInstruction = t('authenticators.enroll.instructions.second');
  const thirdInstruction = t('authenticators.enroll.instructions.third');

  SetTitle(t('navigation.enroll'));
  return (
    <AssuranceWizardLayout>
      <div className={classes.topSpacing}>
        <div className={classes.sidebarGrid}>
          <div>
            <img
              className={classes.phoneImage}
              alt="Imprivata ID"
              src={iidSrc}
              data-testid="iid-icon"
            />
          </div>
          <div className={classes.centeredArea}>
            <Row>
              <div className={classes.header}>
                {t('authenticators.headers.enroll')}
              </div>
            </Row>
            <Row>
              <ol style={{ width: '451px' }}>
                {/* Done this way because we want to render HTML coming from the translations: */}
                <li dangerouslySetInnerHTML={{ __html: firstInstruction }}></li>
                <li
                  dangerouslySetInnerHTML={{ __html: secondInstruction }}
                ></li>
                <li dangerouslySetInnerHTML={{ __html: thirdInstruction }}></li>
              </ol>
            </Row>
            <Form
              form={form}
              layout="vertical"
              name="enroll-iid"
              onChange={handleChange}
              onFinish={enroll}
            >
              <Row className={classes.medPaddedRow}>
                <Form.Item
                  name="serialNumberValue"
                  label={t('authenticators.enroll.serial-number.label')}
                  rules={[
                    {
                      message: t(requiredMsgKey),
                    },
                  ]}
                >
                  <InputBox
                    autoFocus
                    data-testid="enroll-form--serial-number"
                    placeholder=" "
                    style={{
                      width: '400px',
                    }}
                    size="small"
                    type="text"
                    onPaste={e => {
                      e.preventDefault();
                    }}
                    onKeyDown={e => {
                      const specialCharRegex = new RegExp('[0-9a-zA-Z ]');
                      const ctrlKeyCode = [
                        'ArrowLeft',
                        'ArrowRight',
                        'Escape',
                        'Enter',
                        'Ctrl',
                        'Backspace',
                        'Delete',
                      ];
                      if (ctrlKeyCode.includes(e.key) || e.ctrlKey) {
                        // Allow keyboard shortcuts including ctrl+A for selecting all text inside input
                        return true;
                      }

                      const selectedText = document.getSelection();
                      if (selectedText && selectedText.toString().length > 0) {
                        // If a certain part(or all) of input text is selected, we allow editing of the text instead of blocking it
                        // which also means user can increase the string length by selecting and then pasting a longer string
                        return true;
                      }

                      if (!specialCharRegex.test(e.key)) {
                        // special characters are blocked but allow space
                        e.preventDefault();
                        return false;
                      }

                      if (
                        processSerialNumber(
                          (e.target as HTMLInputElement).value,
                        ).length >= MAX_LENGTH_IMPRID_SL_NO_INPUT
                      ) {
                        // block typing if processed text length reaches 14
                        e.preventDefault();
                        return false;
                      }
                      return true;
                    }}
                  />
                </Form.Item>
              </Row>
              <Row>
                <TokenInput
                  label={t('authenticators.enroll.token.label')}
                  width="400px"
                />
              </Row>
              <Row className={classes.shortPaddedRow}>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  label={t('actions.continue')}
                  data-testid="enroll-button"
                  htmlType="submit"
                  disabled={isSaveDisabled}
                />
              </Row>
              <Row className={classes.shortCancelRow}>
                <Button
                  type="text"
                  variant={ButtonVariant.TEXT}
                  label={t('actions.later')}
                  data-testid="cancel-button"
                  onClick={() => {
                    history.push(AUTHENTICATORS_ROUTE + search);
                  }}
                  disabled={false}
                />
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </AssuranceWizardLayout>
  );
};

export default IIDEnrollment;
