import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { cancelSupervisorAuthentication } from '../../../api/authnService';
import { ApiError } from '../../../api/types';
import { RootAction } from '../../../store/rootAction';
import { cancelSupervisorAuthenticationAction } from './actions';
import { addErrorHandlerAction } from '../../../error-handler/store/action';
import { supervisedAssuranceContextType } from '../../../api/constants';
import { jsonToBase64 } from '@imprivata-cloud/data-privacy-js';

export const cancelSupervisorAuthenticationEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(cancelSupervisorAuthenticationAction.request)),
    switchMap(({ payload }) => {
      const inAppSessionHeaderValue = jsonToBase64({
        contextType: supervisedAssuranceContextType,
      });

      return from(
        cancelSupervisorAuthentication(payload, inAppSessionHeaderValue),
      ).pipe(
        map(() => cancelSupervisorAuthenticationAction.success()),
        catchError((error: ApiError) =>
          of(
            cancelSupervisorAuthenticationAction.failure(),
            addErrorHandlerAction({ errorCode: error.code }),
          ),
        ),
      );
    }),
  );
