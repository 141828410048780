import { EventsType } from 'react-idle-timer';

export enum ErrorMessages {
  'AUTHENTICATION_FAILED' = 'Authentication failed',
  'INCORRECT_TENANT_ID' = 'The Tenant ID is not valid. Please see your administrator.',
  'TENANT_ID_NOT_PROVIDED' = 'No Tenant ID provided.',
  'SESSION_EXPIRED' = 'Session expired',
}

export enum ErrorCodes {
  'SESSION_EXPIRED' = 'session-expired',
  'IDP_DISABLED' = 'idp-disabled',
  'CREDENTIALS_EXPIRED' = 'credential-expired',
  'AMBIGUOUS_USERNAME' = 'ambiguous-username',
  // TODO determine what this should be named
  'CLIENT_SESSION_ERROR' = 'client-session-error',

  // `user-invalid-credentials` is scoped to this user-web-ui repo only. it does not come from the BFF or other downstream service
  'USER_INVALID_CREDENTIALS' = 'user-invalid-credentials',
}

export enum ErrorCodesForSupervisorLogin {
  CREDENTIALS_EXPIRED = 'credential-expired',
  AMBIGUOUS_USERNAME = 'errors.ambiguous-username',
  INVALID_CREDENTIALS = 'invalid-credentials',
}

export const QueryParamKey = {
  TENANT_ID: 'tenantId',
  SESSION_ID: 'session_id',
  AUTHN_API_VERSION: 'authnApiVersion',
  WINDOW_CLOSE_TIMEOUT: 'windowCloseSec',
};

export enum StorageKeys {
  SESSION_ID = 'SESSION_ID',
  TENANT_ID = 'TENANT_ID',
  USER = 'USER',
  CODING_CTX_ID = 'CODING_CTX_ID',
  CODING_CTX_DATAKEY = 'CODING_CTX_DATAKEY',
  CODING_CTX_ENCRYPTED_DATAKEY = 'CODING_CTX_ENCRYPTED_DATAKEY',
  IS_SUPERVISOR_AUTHENTICATING = 'isSupervisorAuthenticating',
  KEEP_USER_SESSION_ACTIVE = 'keepUserSessionActive',
  LOGOUT_EVENT_TRIGGERED = 'logoutEventTriggered',
}

export const USER_ACTIVITIES_EVENTS: EventsType[] = [
  'mousemove',
  'keydown',
  'wheel',
  'mousewheel',
  'mousedown',
];
