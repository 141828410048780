// Copyright 2022, Imprivata, Inc.  All rights reserved.

const USER_WEB_PREFIX = '/user-web';
const AUTHN_PREFIX = '/authn-web';

const AUTHENTICATORS_SERVICE_PREFIX =
  `${USER_WEB_PREFIX}/authenticators/v2` as const;

const AUTHN_V3_SERVICE_PREFIX = `${AUTHN_PREFIX}/authn/v3` as const;

const SESSSION_SERVICE_PREFIX = `${USER_WEB_PREFIX}/session/v2` as const;

const SUPERVISED_ASSURANCE_SERVICE_PREFIX =
  `${USER_WEB_PREFIX}/supervised-assurance/v1` as const;
const INDIVIDUAL_ASSURANCE_SERVICE_PREFIX =
  `${USER_WEB_PREFIX}/individual-assurance/v1` as const;

export const authenticators = {
  LIST_AUTHENTICATORS:
    `${AUTHENTICATORS_SERVICE_PREFIX}/authenticators/find` as const,
  ENROLL_AUTHENTICATOR:
    `${AUTHENTICATORS_SERVICE_PREFIX}/authenticator/enroll` as const,
  UNENROLL_AUTHENTICATOR:
    `${AUTHENTICATORS_SERVICE_PREFIX}/authenticator/unenroll` as const,
  UPDATE_ASSURANCE_TYPE:
    `${AUTHENTICATORS_SERVICE_PREFIX}/assurance-type/update` as const,
  GET_PIN_SETTINGS:
    `${AUTHENTICATORS_SERVICE_PREFIX}/pin-settings/get` as const,
};

export const assurance = {
  START_INDIVIDUAL_ASSURANCE:
    `${INDIVIDUAL_ASSURANCE_SERVICE_PREFIX}/assurance/start` as const,
  START_SUPERVISED_ASSURANCE:
    `${SUPERVISED_ASSURANCE_SERVICE_PREFIX}/assurance/start` as const,
  COMPLETE_SUPERVISED_ASSURANCE:
    `${SUPERVISED_ASSURANCE_SERVICE_PREFIX}/assurance/complete` as const,
  CANCEL_SUPERVISED_ASSURANCE:
    `${SUPERVISED_ASSURANCE_SERVICE_PREFIX}/assurance/cancel` as const,

  COMPLETE_INDIVIDUAL_ASSURANCE:
    `${INDIVIDUAL_ASSURANCE_SERVICE_PREFIX}/assurance/complete` as const,
  SEND_OTP_BY_EMAIL:
    `${INDIVIDUAL_ASSURANCE_SERVICE_PREFIX}/otp/send/by-email` as const,
  SEND_OTP_BY_SMS:
    `${INDIVIDUAL_ASSURANCE_SERVICE_PREFIX}/otp/send/by-sms` as const,
  SEND_OTP_BY_VOICE:
    `${INDIVIDUAL_ASSURANCE_SERVICE_PREFIX}/otp/send/by-voice` as const,
  VERIFY_OTP: `${INDIVIDUAL_ASSURANCE_SERVICE_PREFIX}/otp/verify` as const,
  VERIFY_IDENTITY:
    `${SUPERVISED_ASSURANCE_SERVICE_PREFIX}/identity-verification/record` as const,
  VERIFY_AUTHENTICATOR:
    `${SUPERVISED_ASSURANCE_SERVICE_PREFIX}/authenticator/verify` as const,

  ID_PROOFING_START_SESSION:
    `${INDIVIDUAL_ASSURANCE_SERVICE_PREFIX}/id-proofing-session/start` as const,
  ID_PROOFING_CHECK_STATUS:
    `${INDIVIDUAL_ASSURANCE_SERVICE_PREFIX}/id-proofing-status/get` as const,
};

export const authn = {
  GET_NEXT_FACTORS: `${AUTHN_V3_SERVICE_PREFIX}/next-factors/get` as const,
  GET_ORG_PREFERENCES:
    `${AUTHN_PREFIX}/org-preferences/v1/org-preferences/get` as const,
  CREATE_SESSION_V3: `${AUTHN_V3_SERVICE_PREFIX}/authn-session/start` as const,
  AUTHENTICATE_V3: `${AUTHN_V3_SERVICE_PREFIX}/authenticate` as const,
  TERMINATE_SESSION:
    `${AUTHN_V3_SERVICE_PREFIX}/authn-session/terminate` as const,
};

export const authz = {
  START_CLIENT_SESSION:
    `${SESSSION_SERVICE_PREFIX}/client-user-session/start` as const,
  LOGOUT: `${SESSSION_SERVICE_PREFIX}/logout` as const,
};
