import { Tag } from 'antd';
import classes from './Chip.module.less';

// Note: at some point this will migrate to the component library and will be
// more full-featured:
const Chip: React.FC<{
  label: React.ReactNode;
}> = ({ label }) => {
  return (
    <Tag data-testid="chip" className={classes.tagRender}>
      {label}
    </Tag>
  );
};

export default Chip;
