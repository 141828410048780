// Copyright 2022, Imprivata, Inc.  All rights reserved.

import axios from 'axios';
import getConfigs from '../appConfigUtils';
import { CLIENT_NAME, Headers } from './constants';
import { jsonToBase64 } from '@imprivata-cloud/data-privacy-js';

const { API_URL } = getConfigs();

const securityHeaders = {
  [Headers.StrictTransportSecurity]: 'max-age=86400;includeSubDomains',
  [Headers.XSSProtection]: '1;mode=block',
  [Headers.XFrameOptions]: 'DENY',
  [Headers.XContentTypeOptions]: 'nosniff',
};

const clientHeaders = {
  [Headers.ImprClientName]: jsonToBase64({ name: CLIENT_NAME }),
};

export const client = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    ...securityHeaders,
    ...clientHeaders,
  },
});

export const noCredentialsClient = axios.create({
  headers: {
    ...securityHeaders,
  },
});

class FetchClient {
  headers: Record<string, string> = { ...clientHeaders };

  client(
    url: string,
    payload: Record<string, unknown>,
    omitHeaders: string[] = [],
  ) {
    const headers = Object.keys(this.headers)
      .filter(k => !omitHeaders.includes(k))
      .reduce((sum, curr) => {
        return { ...sum, [curr]: this.headers[curr] };
      }, {});

    return fetch(API_URL + url, {
      headers: {
        'content-type': 'application/json',
        ...headers,
        ...clientHeaders,
      },
      keepalive: true,
      body: JSON.stringify(payload),
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    });
  }

  setHeader(header: Headers, value: string) {
    this.headers[header] = value;
  }

  removeHeader(header: Headers) {
    delete this.headers[header];
  }
}

export const fetchWithKeepAlive = new FetchClient();
