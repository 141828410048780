// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { addErrorHandlerAction, clearErrorHandlerAction } from './action';
import { RootAction } from '../../store/rootAction';
import { ErrorState } from '../types';
export const errorHandlerState: ErrorState = {
  errorCode: '',
  errorMessageParams: {},
};

export const errorHandler = combineReducers<ErrorState>({
  errorCode: createReducer<string, RootAction>(errorHandlerState.errorCode)
    .handleAction(addErrorHandlerAction, (_, { payload }) => payload.errorCode)
    .handleAction(clearErrorHandlerAction, () => ''),
  errorMessageParams: createReducer<
    Record<string, string> | undefined,
    RootAction
  >(errorHandlerState.errorMessageParams)
    .handleAction(addErrorHandlerAction, (_, { payload }) =>
      payload.errorMessageParams ? payload.errorMessageParams : {},
    )
    .handleAction(clearErrorHandlerAction, () => {
      return {};
    }),
});
